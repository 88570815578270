import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useHistory, Link, useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import AdminApiModule from "../../components/AdminApi/Api/api";
import Chip from "@mui/material/Chip";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import ImageIcon from "@mui/icons-material/Image";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Modal } from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { BASE_URL } from "../../components/config/config";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import "./veiwTicket.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function ViewTicket() {
  let location = useLocation();
  const ticketData = location?.state?.ticketData || null;
  let ticketId = location?.search
    ? new URLSearchParams(location.search).get("ticketId")
    : null;
  const remarksFromDocument = location.state?.remarks || "";
  const [isLoading, setIsLoading] = useState(false);
  const [doesTicketExists, setDoesTicketExists] = useState(false);
  const [ticketDetails, setTicketDetails] = useState({});
  const [feedBackList, setFeedBackList] = useState({});
  const [broadCastList, setBroadCastList] = useState([]);
  const [remarkModalOpen, setRemarkModalOpen] = useState(false);
  const [remarkValue, setRemarkValue] = useState("");
  const [ticketIdd, setTicketId] = useState(null);
  const [centerObjId, setCenterObjId] = useState(null);
  const globalState = useSelector((state) => state.AdminReducer);
  const [technicianMessage, setTechnicianMessage] = useState("");
  const [specificRequirement, setSpecificRequirement] = useState("");
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [remarks, setRemarks] = useState("");
  const handleOpenCancelModal = () => setCancelModalOpen(true);
  const handleCloseCancelModal = () => setCancelModalOpen(false);
  const [refundModalOpen, setRefundModalOpen] = useState(false);
  const [refundType, setRefundType] = useState("");
  const [refundAmount, setRefundAmount] = useState("");
  const [refundRemarks, setRefundRemarks] = useState("");
  const [centerObjIdd, setCenterObjIdd] = useState(null);
  const [ticketObjId, setTicketObjId] = useState(null);
  const [isBroadcasted, setIsBroadcasted] = useState(false);

  const handleOpenRefundModal = (centerId, ticketId) => {
    setCenterObjIdd(centerId);
    setTicketObjId(ticketId);
    setRefundModalOpen(true);
  };

  const handleCloseRefundModal = () => {
    setRefundModalOpen(false);

    setCenterObjIdd(null);
    setTicketObjId(null);
  };

  const history = useHistory();
  useEffect(() => {
    // Set specificRequirement state with the old value from ticketDetails
    setSpecificRequirement(ticketDetails?.specific_requirement || "");
  }, [ticketDetails]);
  const [name, setName] = useState("");
  const startLoading = () => {
    setIsLoading(true);
  };

  const stopLoading = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    getAllTickets(ticketId);
  }, []);

  const downloadImage = (url, fileName) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getAllTickets = (ticketId) => {
    startLoading();

    let payload = {
      ticket_obj_id: ticketId,
    };

    new AdminApiModule()
      .getSingleTicket(payload)
      .then((res) => {
        if (res?.data?.results?.length) {
          setDoesTicketExists(true);
          setTicketDetails(res?.data?.results?.[0]);
          setFeedBackList(res?.data?.feedBackList?.[0]);
          setBroadCastList(res?.data?.broadCastList);
        }

        stopLoading();
      })
      .catch((err) => {
        stopLoading();
      });
  };

  const handleWhatsAppClick = (
    phoneNumber,
    locality,
    city,
    service,
    pincode
  ) => {
    // Format the phone number to remove special characters
    const formattedPhoneNumber = phoneNumber.replace(/[^\d]/g, "");

    // Define the message with dynamic variables
    const message = `
        नमस्ते 
        We have a lead in *${pincode} ,${locality}, ${city}* ( Immediately ) - *${service}*  (Direct Payment by Customer) 
        If interested,
        Playstore se  Update kariye - OniT Partner App https://play.google.com/store/apps/details?id=com.onit.partners
        नई लीड्स एक्सेप्ट करने के लिए KYC कंप्लीट करिए (profile picture, training certificate, Adhaar, PAN जरूरी है)
        वॉलेट में मिनिमम 99/– होना चाहिए

        Profile से अपना QR शेयर करते रहिये और बिना किसी कमिशन के लीड जनरेट करते रहिये - 
        कोई भी सवाल हो तो 7836072190 पर कॉल/व्हाट्सएप करिये|  
        अगर नहीं करना चाहते तो - Not Interested लिखकर रिप्लाई करिये
    `;

    // Encode the message to be included in the URL
    const encodedMessage = encodeURIComponent(message);

    // Open WhatsApp web with the formatted phone number and predefined message
    window.open(
      `https://web.whatsapp.com/send?phone=${formattedPhoneNumber}&text=${encodedMessage}`,
      "_blank"
    );
  };
  const handleWhatsAppClick1 = (number, name) => {
    if (number) {
      const formattedPhoneNumber = number.replace(/[^\d]/g, "");
      const message = `Dear ${name} its being long since we served you, just wanted to remind about Onit Services for any of your Home Services `;
      const encodedMessage = encodeURIComponent(message);
      window.open(
        `https://web.whatsapp.com/send?phone=${formattedPhoneNumber}&text=${encodedMessage}`,
        "_blank"
      );
    } else {
      console.error("Phone number is not defined or invalid");
    }
  };

  const handleSubmitReq = (ticketobjid) => {
    const payload = {
      new_requirement: specificRequirement,
      ticket_obj_id: ticketobjid,
    };
    fetch(`${BASE_URL}admin/update-specific-requirement`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.ok) {
          // Handle success response
          console.log("Requirements saved successfully");
        } else {
          // Handle error response
          console.error("Error saving requirements");
        }
      })
      .catch((error) => {
        console.error("Error saving requiremnets:", error);
      });
  };

  const handleOpenRemarkModal = (
    ticketId,
    centerId,
    technicianMessage,
    name
  ) => {
    setRemarkModalOpen(true);
    setTicketId(ticketId);
    setCenterObjId(centerId);
    setTechnicianMessage(technicianMessage);
    setName(name);
  };

  const handleCloseRemarkModal = () => {
    setRemarkModalOpen(false);
    setRemarkValue("");
    history.push(`/view-ticket?ticketId=${ticketDetails?._id}`);
  };

  const handleEditCenter = (id) => {
    history.push("/add-center?edit=" + id);
  };
  const [toast, setToast] = useState({
    open: false,
    msg: "",
    duration: 5000,
    type: "success",
  });

  const showDialog = (open, type, msg) => {
    setToast({ open, msg, duration: 5000, type });
  };
  // const handleRefund = (centerObjId, ticketObjId) => {
  //     const payload = {
  //         center_obj_id: centerObjId,
  //         ticket_obj_id: ticketObjId,
  //     };
  //     fetch(`${BASE_URL}admin/send-refund-in-wallet-technician`, {
  //         method: 'POST',
  //         headers: {
  //             'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify(payload)
  //     })
  //     .then(response => response.json())
  //     .then(data => {
  //         console.log('Refund response:', data);
  //         showDialog(true, data.success === 1 ? 'success' : 'error', data.message);
  //     })
  //     .catch(error => {
  //         console.error('Error in refund:', error);
  //         showDialog(true, 'error', 'An error occurred while processing the refund.');
  //     });
  // };

  const handleRefundSubmit = () => {
    if (!refundType || !refundAmount || !refundRemarks) {
      alert("Please fill in all fields.");
      return;
    }
    console.log(
      "=======",
      ticketObjId,
      "========",
      centerObjIdd,
      "======++++",
      refundRemarks,
      refundAmount,
      refundType,
      globalState?.adminDetails?.user_name
    );
    const payload = {
      ticket_obj_id: ticketObjId, // Replace with actual value
      center_obj_id: centerObjIdd, // Replace with actual value
      refundRemarks,
      refundAmount,
      refundType,
      Name: globalState?.adminDetails?.user_name,
    };
    fetch(`${BASE_URL}admin/send-refund-in-wallet-technician`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Refund response:", data);
        showDialog(
          true,
          data.success === 1 ? "success" : "error",
          data.message
        );
      })
      .catch((error) => {
        console.error("Error in refund:", error);
        showDialog(
          true,
          "error",
          "An error occurred while processing the refund."
        );
      });

    setToast({
      open: true,
      msg: "Refund processed successfully",
      type: "success",
      duration: 3000,
    });
    // Reset fields after submission if needed
    setRefundType("");
    setRefundAmount("");
    setRefundRemarks("");

    handleCloseRefundModal();
  };

  const broadcast = (id) => {
    const apiUrl = `${BASE_URL}technicianApp/ticket-broadcast`;
    console.log("===", apiUrl);
    const requestData = {
      ticket_obj_id: id,
    };

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        showDialog(true, "success", "Request broadcasted successfully");

        // ✅ Change button color to green
        setIsBroadcasted(true);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
        showDialog(true, "error", "Failed to broadcast request");

        // ❌ Keep the button color unchanged if broadcast fails
        setIsBroadcasted(false);
      });
  };

  const editticket = (id) => {
    history.push("/admin-ticket?edit=" + id);
  };

  const CancelTicket = async (id, name, remarks) => {
    console.log("=========", id, name, remarks);
    try {
      // POST request to update remarks
      await axios.post(`${BASE_URL}admin/onboarding-team-remarks`, {
        onboarding_team_remarks: remarks,
        onboarding_team_name: name, // Replace with the actual username
        ticket_obj_id: id, // Replace with the actual ticket ID
      });
      console.log("Remarks updated successfully.");

      // PUT request to cancel the ticket
      await axios.put(`${BASE_URL}admin/cancelTickets/${id}`);
      console.log("Request cancelled successfully.");

      // Close the modal after both requests succeed
      handleCloseCancelModal();
    } catch (error) {
      console.error("Error cancelling the request:", error);
      // Handle errors appropriately (e.g., show error message to user)
    }
  };
  const handleSaveRemark = (ticketIdd, centerObjId) => {
    // Prepare the payload
    const payload = {
      ticket_obj_id: ticketIdd,
      center_obj_id: centerObjId,
      onboarding_technician_remarks: remarkValue,
      onboarding_team_name: globalState?.adminDetails?.user_name,
      // Replace 'your_username_here' with the actual username
    };
    fetch(`${BASE_URL}admin/add-broadcast-remarks-team`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.ok) {
          // Handle success response
          console.log("Remark saved successfully");
          // Close the modal

          handleCloseRemarkModal();
          history.go(0);
        } else {
          // Handle error response
          console.error("Error saving remark");
          // Close the modal if needed
          handleCloseRemarkModal();
        }
      })
      .catch((error) => {
        console.error("Error saving remark:", error);
        // Close the modal if needed
        handleCloseRemarkModal();
      });
  };

  if (isLoading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={stopLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (!doesTicketExists) {
    return <>Invalid Request id </>;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <div>
        <Link to="/all-tickets">
          <IconButton
            color="primary"
            aria-label="back"
            sx={{ position: "absolute", top: 16, left: 16 }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Link>
      </div>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ width: "48%" }}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ border: "1px solid #ccc", padding: "8px" }}>
                    Request Id:
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ccc", padding: "8px" }}>
                    {ticketDetails?.ticket_id}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px solid #ccc", padding: "8px" }}>
                    Request status:
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ccc", padding: "8px" }}>
                    <span
                      style={{
                        fontWeight:
                          ticketDetails?.ticket_status ===
                          "YourHighlightedStatus"
                            ? "bold"
                            : "normal",
                      }}
                    >
                      {ticketDetails?.ticket_status}
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px solid #ccc", padding: "8px" }}>
                    Request closing price:
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ccc", padding: "8px" }}>
                    {ticketDetails?.closing_ticket_price
                      ? ticketDetails?.closing_ticket_price
                      : "NIL"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px solid #ccc", padding: "8px" }}>
                    Request created by:
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ccc", padding: "8px" }}>
                    {ticketDetails?.ticket_created_by}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: "1px solid #ccc", padding: "8px" }}>
                    Specific Requirements:
                  </TableCell>
                  <TableCell sx={{ border: "1px solid #ccc", padding: "8px" }}>
                    <TextField
                      value={specificRequirement} // Bind the value to specificRequirement state
                      onChange={(e) => setSpecificRequirement(e.target.value)} // Update specificRequirement state on change
                      fullWidth
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleSubmitReq(ticketDetails?._id)}
                    >
                      Submit
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <table style={{ width: "48%", borderCollapse: "collapse" }}>
          <tbody>
            <tr>
              <td
                style={{
                  width: "48%",
                  border: "1px solid #ccc",
                  padding: "8px",
                  fontWeight: "bolder",
                }}
              >
                Operation Team Remarks:
              </td>
              <td
                style={{
                  width: "52%",
                  border: "1px solid #ccc",
                  padding: "8px",
                  fontWeight: "bolder",
                }}
              >
                {ticketDetails?.remarks.onboarding_team_remarks} -{" "}
                {ticketDetails?.remarks.onboarding_team_name}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "48%",
                  border: "1px solid #ccc",
                  padding: "8px",
                }}
              >
                Is Technician Assigned:
              </td>
              <td
                style={{
                  width: "52%",
                  border: "1px solid #ccc",
                  padding: "8px",
                }}
              >
                <Chip
                  label={
                    ticketDetails?.is_technician_assigned
                      ? "Assigned"
                      : "Not Assigned"
                  }
                  sx={{ height: "20px" }}
                />
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "48%",
                  border: "1px solid #ccc",
                  padding: "8px",
                  height: "20px",
                }}
              >
                Broadcast status:
              </td>
              <td
                style={{
                  width: "52%",
                  border: "1px solid #ccc",
                  padding: "8px",
                  height: "20px",
                }}
              >
                <Chip
                  label={ticketDetails?.broadcast_status}
                  sx={{ height: "20px" }}
                />
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "48%",
                  border: "1px solid #ccc",
                  padding: "8px",
                  height: "20px",
                }}
              >
                Happy Code:
              </td>
              <td
                style={{
                  width: "52%",
                  border: "1px solid #ccc",
                  padding: "8px",
                  height: "42px",
                }}
              >
                <Chip
                  label={ticketDetails?.code?.happy_code}
                  sx={{ height: "20px" }}
                />
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "48%",
                  border: "1px solid #ccc",
                  padding: "8px",
                  height: "20px",
                }}
              >
                Unhappy Code:
              </td>
              <td
                style={{
                  width: "52%",
                  border: "1px solid #ccc",
                  padding: "8px",
                  height: "20px",
                }}
              >
                <Chip
                  label={ticketDetails?.code?.unhappy_code}
                  sx={{ height: "20px" }}
                />
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "48%",
                  border: "1px solid #ccc",
                  padding: "8px",
                  height: "20px",
                }}
              >
                Offer Code:
              </td>
              <td
                style={{
                  width: "52%",
                  border: "1px solid #ccc",
                  padding: "8px",
                  height: "20px",
                }}
              >
                <Chip
                  label={ticketDetails?.offers_applied?.offer_code}
                  sx={{ height: "20px" }}
                />
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "48%",
                  border: "1px solid #ccc",
                  padding: "8px",
                  height: "20px",
                }}
              >
                Happy/Unhappy:
              </td>
              <td
                style={{
                  width: "52%",
                  border: "1px solid #ccc",
                  padding: "8px",
                  height: "20px",
                }}
              >
                <Chip
                  label={ticketDetails?.remarks?.message}
                  sx={{ height: "20px" }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Box sx={{ width: "48%" }}>
          {ticketDetails?.revisit_status && (
            <>
              <tr>
                <td
                  style={{
                    border: "2px solid black",
                    padding: "8px",
                    fontWeight: "bold",
                    width: 152,
                  }}
                >
                  Revisit Status:
                </td>
                <td
                  style={{
                    border: "2px solid black",
                    padding: "8px",
                    width: 370,
                  }}
                >
                  {ticketDetails?.ticket_revisit_status}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "2px solid black",
                    padding: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Revisit Price:
                </td>
                <td style={{ border: "2px solid black", padding: "8px" }}>
                  {ticketDetails?.revisit_price}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "2px solid black",
                    padding: "8px",
                    fontWeight: "bold",
                  }}
                >
                  Revisit Remarks:
                </td>
                <td style={{ border: "2px solid black", padding: "8px" }}>
                  {ticketDetails?.remarks?.revisit_remarks}
                </td>
              </tr>
            </>
          )}
          <Divider />
          <tr style={{ border: "1px solid #ccc" }}>
            <td
              style={{
                border: "1px solid #ccc",
                padding: "8px",
                fontWeight: "bold",
              }}
            >
              Created At:
            </td>
            <td style={{ border: "1px solid #ccc", padding: "8px" }}>
              {new Date(ticketDetails?.createdAt).toLocaleString("en-IN", {
                timeZone: "Asia/Kolkata",
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: true,
              })}
            </td>
          </tr>
          <tr style={{ border: "1px solid #ccc" }}>
            <td
              style={{
                border: "1px solid #ccc",
                padding: "8px",
                fontWeight: "bold",
              }}
            >
              Accepted At:
            </td>
            <td style={{ border: "1px solid #ccc", padding: "8px" }}>
              {ticketDetails?.acceptedAt
                ? new Date(ticketDetails.acceptedAt).toLocaleString("en-IN", {
                    timeZone: "Asia/Kolkata",
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: true,
                  })
                : "----"}
            </td>
          </tr>
          <tr style={{ border: "1px solid #ccc" }}>
            <td
              style={{
                border: "1px solid #ccc",
                padding: "8px",
                fontWeight: "bold",
              }}
            >
              Assigned At:
            </td>
            <td style={{ border: "1px solid #ccc", padding: "8px" }}>
              {ticketDetails?.assignedAt
                ? new Date(ticketDetails.assignedAt).toLocaleString("en-IN", {
                    timeZone: "Asia/Kolkata",
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: true,
                  })
                : "----"}
            </td>
          </tr>
          <tr style={{ border: "1px solid #ccc" }}>
            <td
              style={{
                border: "1px solid #ccc",
                padding: "8px",
                fontWeight: "bold",
              }}
            >
              Closed At:
            </td>
            <td style={{ border: "1px solid #ccc", padding: "8px" }}>
              {ticketDetails?.closedAt
                ? new Date(ticketDetails.closedAt).toLocaleString("en-IN", {
                    timeZone: "Asia/Kolkata",
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: true,
                  })
                : "----"}
            </td>
          </tr>
          <tr style={{ border: "1px solid #ccc" }}>
            <td
              style={{
                border: "1px solid #ccc",
                padding: "8px",
                fontWeight: "bold",
              }}
            >
              Service provided for:
            </td>
            <td style={{ border: "1px solid #ccc", padding: "8px" }}>
              {ticketDetails?.service_provided_for?.service_name}
            </td>
          </tr>
        </Box>

        <table
          style={{
            width: "48%",
            border: "1px solid #ccc",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              <th
                colSpan={2}
                style={{
                  border: "1px solid #ccc",
                  padding: "8px",
                  fontWeight: "bold",
                }}
              >
                Remarks
              </th>
            </tr>
          </thead>
          <tbody
            style={{
              width: "100%",
              border: "1px solid #ccc",
              bgcolor: "background.paper",
            }}
          >
            <tr style={{ border: "1px solid #ccc" }}>
              <td
                style={{
                  border: "1px solid #ccc",
                  padding: "8px",
                  fontWeight: "bold",
                }}
              >
                Request closure remarks:
              </td>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                {ticketDetails?.remarks?.technician_remarks}
              </td>
            </tr>
            <tr style={{ border: "1px solid #ccc" }}>
              <td
                style={{
                  border: "1px solid #ccc",
                  padding: "8px",
                  fontWeight: "bold",
                }}
              >
                Serial Number:
              </td>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                {ticketDetails?.punch_serialnumber}
              </td>
            </tr>
            <tr style={{ border: "1px solid #ccc" }}>
              <td
                style={{
                  border: "1px solid #ccc",
                  padding: "8px",
                  fontWeight: "bold",
                }}
              >
                Verified remarks:
              </td>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                {ticketDetails?.remarks?.admin_remarks}
              </td>
            </tr>
            <tr style={{ border: "1px solid #ccc" }}>
              <td
                style={{
                  border: "1px solid #ccc",
                  padding: "8px",
                  fontWeight: "bold",
                }}
              >
                Verified By:
              </td>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                {ticketDetails?.approved_by}
              </td>
            </tr>
            <tr style={{ border: "1px solid #ccc" }}>
              <td
                style={{
                  border: "1px solid #ccc",
                  padding: "8px",
                  fontWeight: "bold",
                }}
              >
                Customer Remarks:
              </td>
              <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                {ticketDetails?.messages?.customer_message}
              </td>
            </tr>
          </tbody>
        </table>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Box sx={{ width: "48%" }}>
          <Item>
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Customer Details
              <IconButton
                onClick={() =>
                  handleWhatsAppClick1(
                    ticketDetails?.personal_details?.primary_phone
                      ?.mobile_number,
                    ticketDetails?.personal_details?.name
                  )
                }
                sx={{ marginLeft: "auto", fontSize: "1.5rem" }}
              >
                <WhatsAppIcon
                  sx={{ color: "green", fontSize: "2.5rem", padding: "1px" }}
                />
              </IconButton>
            </Typography>
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                  primary="Customer Name"
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {ticketDetails?.personal_details?.name}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    alt="Travis Howard"
                    src="/static/images/avatar/2.jpg"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary="Customer Phone"
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {ticketDetails?.personal_details?.primary_phone
                          ?.country_code +
                          "  " +
                          ticketDetails?.personal_details?.primary_phone
                            ?.mobile_number}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                </ListItemAvatar>
                <ListItemText
                  primary="Address details"
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {"House Number" +
                          " -  " +
                          ticketDetails?.address_details?.house_number}
                      </Typography>
                      <br />
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {"Locality" +
                          " -  " +
                          ticketDetails?.address_details?.locality}
                      </Typography>
                      <br />
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {"Pincode" +
                          " -  " +
                          ticketDetails?.address_details?.pincode}
                      </Typography>
                      <br />
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {"City" + " -  " + ticketDetails?.address_details?.city}
                      </Typography>
                      <br />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {"State" +
                            " -  " +
                            ticketDetails?.address_details?.state}
                        </Typography>
                        <a
                          href={`https://app.onit.services/#/ServiceStatus/?${ticketDetails?._id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ fontWeight: "bold", color: "red" }}
                        >
                          Tracking Link
                        </a>
                      </div>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
          </Item>
        </Box>
        <Box sx={{ width: "48%" }}>
          <TableContainer>
            <Table style={{ width: "100%" }}>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ccc",
                      fontWeight: "bold",
                      padding: "8px",
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                  >
                    Assigned Details
                  </td>
                </tr>
                <tbody>
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    {ticketDetails?.is_technician_assigned ? (
                      <>
                        <tr style={{ border: "1px solid #ccc" }}>
                          <td
                            style={{
                              border: "1px solid #ccc",
                              fontWeight: "bold",
                              padding: "8px",
                            }}
                          >
                            Assigned Center Name:
                          </td>
                          <td
                            style={{ border: "1px solid #ccc", padding: "8px" }}
                          >
                            {
                              ticketDetails?.assigned_ids?.assigned_center_id
                                ?.center_name
                            }
                          </td>
                        </tr>
                        <tr style={{ border: "1px solid #ccc" }}>
                          <td
                            style={{
                              border: "1px solid #ccc",
                              fontWeight: "bold",
                              padding: "8px",
                            }}
                          >
                            Assigned Center address:
                          </td>
                          <td
                            style={{ border: "1px solid #ccc", padding: "8px" }}
                          >
                            {ticketDetails?.assigned_ids?.assigned_center_id
                              ?.address_details?.address_line &&
                              `${ticketDetails?.assigned_ids?.assigned_center_id?.address_details?.address_line} - `}
                            {ticketDetails?.assigned_ids?.assigned_center_id
                              ?.address_details?.city &&
                              `${ticketDetails?.assigned_ids?.assigned_center_id?.address_details?.city} - `}
                            {ticketDetails?.assigned_ids?.assigned_center_id
                              ?.address_details?.state &&
                              `${ticketDetails?.assigned_ids?.assigned_center_id?.address_details?.state} - `}
                            {ticketDetails?.assigned_ids?.assigned_center_id
                              ?.address_details?.country &&
                              `${ticketDetails?.assigned_ids?.assigned_center_id?.address_details?.country} - `}
                            {ticketDetails?.assigned_ids?.assigned_center_id
                              ?.address_details?.pincode &&
                              `${ticketDetails?.assigned_ids?.assigned_center_id?.address_details?.pincode}`}
                          </td>
                        </tr>
                        <tr style={{ border: "1px solid #ccc" }}>
                          <td
                            style={{
                              border: "1px solid #ccc",
                              fontWeight: "bold",
                              padding: "8px",
                            }}
                          >
                            {" "}
                            Center INA Code:
                          </td>
                          <td
                            style={{ border: "1px solid #ccc", padding: "8px" }}
                          >
                            {
                              ticketDetails?.assigned_ids?.assigned_center_id
                                ?.qr_details?.qr_id
                            }
                          </td>
                        </tr>
                        <tr style={{ border: "1px solid #ccc" }}>
                          <td
                            style={{
                              border: "1px solid #ccc",
                              fontWeight: "bold",
                              padding: "8px",
                            }}
                          >
                            Assigned Technician Name:
                          </td>
                          <td
                            style={{ border: "1px solid #ccc", padding: "8px" }}
                          >
                            {
                              ticketDetails?.assigned_ids
                                ?.assigned_technician_id?.personal_details?.name
                            }
                          </td>
                        </tr>
                      </>
                    ) : (
                      <tr style={{ border: "1px solid #ccc" }}>
                        <td
                          style={{ border: "1px solid #ccc", padding: "8px" }}
                          colSpan={2}
                        >
                          Not assigned
                        </td>
                      </tr>
                    )}
                    <tr style={{ border: "1px solid #ccc" }}>
                      <td
                        style={{
                          border: "1px solid #ccc",
                          fontWeight: "bold",
                          padding: "8px",
                        }}
                      >
                        Customer Called Technician:
                      </td>
                      <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                        {ticketDetails?.call_count?.consumer_call_count}
                      </td>
                    </tr>
                  </table>
                </tbody>
              </table>
            </Table>
          </TableContainer>

          <Item sx={{ marginTop: "20px" }}>
            <Typography variant="h5">Onsite Pictures</Typography>
            <List
              sx={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                gap: "0",
                bgcolor: "background.paper",
                margin: 0,
                padding: 0,
              }}
            >
              {ticketDetails?.onsite_pictures?.length ? (
                ticketDetails.onsite_pictures.map((image, index) => (
                  <ListItem
                    key={index}
                    sx={{ flex: "0 0 calc(25.33% - 8px)", padding: 0 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "0",
                          }}
                        >
                          <img
                            src={image}
                            alt={`Image ${index + 1}`}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </Avatar>
                      </ListItemAvatar>
                      <div style={{ marginTop: "8px", padding: 0 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            downloadImage(image, `Image${index + 1}.png`)
                          }
                          sx={{ width: "100%", height: "20px", padding: 0 }}
                        >
                          Download
                        </Button>
                      </div>
                    </div>
                  </ListItem>
                ))
              ) : (
                <Typography>No onsite pictures available</Typography>
              )}
            </List>
          </Item>
          <Button
            variant="contained"
            onClick={() => broadcast(ticketDetails?._id)}
            sx={{
              marginTop: "10px",
              marginRight: "10px",
              backgroundColor: isBroadcasted ? "green" : "primary.main",
              "&:hover": {
                backgroundColor: isBroadcasted ? "darkgreen" : "primary.dark",
              },
            }}
          >
            {isBroadcasted ? "Broadcasted" : "Broadcast"}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => editticket(ticketDetails?._id)}
            sx={{ marginTop: "10px", marginRight: "10px" }}
          >
            Edit Request
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpenCancelModal(ticketDetails?._id)}
            sx={{ marginTop: "10px", backgroundColor: "red" }}
          >
            Cancel
          </Button>
        </Box>
      </Box>

      <Item sx={{ marginTop: "20px" }}>
        <Typography variant="h5">Feed back list</Typography>
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          {feedBackList?.feedBackResponse?.length
            ? feedBackList?.feedBackResponse?.map((ite, index) => (
                <ListItem key={index}>
                  <ListItemAvatar>
                    <Avatar>
                      <ImageIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={ite?.question + " ? "}
                    secondary={ite?.answer}
                  />
                </ListItem>
              ))
            : "No feed back given"}
        </List>
      </Item>
      <Item sx={{ marginTop: "20px" }}>
        <Typography variant="h5">Broadcast list</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Center Name</TableCell>
                {/* <TableCell>Last Seen</TableCell> */}
                <TableCell>Center Id</TableCell>
                <TableCell>Center Phone</TableCell>
                <TableCell>Rating</TableCell>
                <TableCell>Subscription</TableCell>
                <TableCell>OTP Verified</TableCell>
                <TableCell>Status of Broadcast</TableCell>
                <TableCell>Closed Request</TableCell>
                <TableCell>Chat</TableCell>
                <TableCell>Remarks</TableCell>
                <TableCell>Edit Center</TableCell>
                <TableCell>Refund</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {broadCastList.reverse().map(
                (item, index) =>
                  !item.center_obj_id?.disabled_for
                    ?.accepting_broadcast_ticket && (
                    <TableRow
                      key={index}
                      style={{
                        background:
                          item.status_of_ticket === "ACCEPTED" ||
                          item.status_of_ticket === "CLOSED"
                            ? "lightgreen"
                            : item.center_obj_id?.payment_details
                                ?.paid_for_onboarding_kit
                            ? "#22d2f1"
                            : "white",
                      }}
                    >
                      <TableCell>{item.center_obj_id?.center_name}</TableCell>
                      {/* <TableCell>{item.center_obj_id?.center_name}</TableCell>F */}
                      <TableCell>
                        {item.center_obj_id?.qr_details?.qr_id}
                      </TableCell>
                      <TableCell>
                        {
                          item.center_obj_id?.personal_details?.phone
                            ?.mobile_number
                        }
                      </TableCell>
                      <TableCell>
                        {
                          item?.center_obj_id?.count_details
                            ?.average_ticket_rating
                        }
                      </TableCell>
                      <TableCell>
                        {item.center_obj_id?.payment_details
                          ?.paid_for_onboarding_kit
                          ? "Subscribed"
                          : "Not Subscribed"}
                      </TableCell>
                      <TableCell>
                        {item?.center_obj_id?.otp_details?.is_otp_verfied
                          ? "YES"
                          : "NO"}
                      </TableCell>
                      <TableCell>{item?.status_of_ticket}</TableCell>
                      <TableCell>
                        {
                          item?.center_obj_id?.count_details
                            ?.closed_ticket_count
                        }
                      </TableCell>
                      <TableCell>
                        <Box
                          border={2}
                          borderColor="green"
                          borderRadius={2}
                          p={0.001}
                          backgroundColor="transparent"
                        >
                          <IconButton
                            onClick={() =>
                              handleWhatsAppClick(
                                item.center_obj_id?.personal_details?.phone
                                  ?.mobile_number,
                                ticketDetails?.address_details?.locality,
                                ticketDetails?.address_details?.city,
                                ticketDetails?.specific_requirement,
                                ticketDetails?.address_details?.pincode
                              )
                            }
                          >
                            <WhatsAppIcon
                              sx={{
                                color: "green",
                                fontSize: 30,
                                padding: "1px",
                              }}
                            />
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() =>
                            handleOpenRemarkModal(
                              item?.ticket_obj_id?._id,
                              item?.center_obj_id?._id,
                              item?.remarks?.technician_message,
                              item?.remarks?.onboarding_team_name
                            )
                          }
                          variant="outlined"
                          sx={{
                            backgroundColor: item?.remarks?.technician_message
                              ? "green"
                              : "blue",
                            color: "white",
                            "&:hover": {
                              color: "black",
                            },
                          }}
                        >
                          Remark
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() =>
                            handleEditCenter(item?.center_obj_id?._id)
                          }
                        >
                          Edit
                        </Button>
                      </TableCell>
                      <TableCell>
                        {(item.status_of_ticket === "ACCEPTED" ||
                          item.status_of_ticket === "CLOSED") && (
                          <Button
                            onClick={() =>
                              handleOpenRefundModal(
                                item?.center_obj_id?._id,
                                item?.ticket_obj_id?._id
                              )
                            }
                            variant="outlined"
                            color="secondary"
                          >
                            Refund
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  )
              )}

              <TableRow>
                <TableCell
                  colSpan={12}
                  style={{ fontWeight: "bold", fontSize: "16px" }}
                >
                  Centers that are disabled for Accepting Requests:
                </TableCell>
              </TableRow>

              {broadCastList.reverse().map(
                (item, index) =>
                  item.center_obj_id?.disabled_for
                    ?.accepting_broadcast_ticket && (
                    <TableRow key={index} style={{ background: "#F14122" }}>
                      <TableCell>{item.center_obj_id?.center_name}</TableCell>
                      <TableCell>
                        {item.center_obj_id?.qr_details?.qr_id}
                      </TableCell>
                      <TableCell>
                        {
                          item.center_obj_id?.personal_details?.phone
                            ?.mobile_number
                        }
                      </TableCell>
                      <TableCell>
                        {
                          item?.center_obj_id?.count_details
                            ?.average_ticket_rating
                        }
                      </TableCell>
                      <TableCell>
                        {item.center_obj_id?.payment_details
                          ?.paid_for_onboarding_kit
                          ? "Subscribed"
                          : "Not Subscribed"}
                      </TableCell>
                      <TableCell>{item?.status_of_ticket}</TableCell>
                      <TableCell>
                        {
                          item?.center_obj_id?.count_details
                            ?.closed_ticket_count
                        }
                      </TableCell>
                      <TableCell>
                        <Box
                          border={2}
                          borderColor="green"
                          borderRadius={2}
                          p={0.001}
                          backgroundColor="transparent"
                        >
                          <IconButton
                            onClick={() =>
                              handleWhatsAppClick(
                                item.center_obj_id?.personal_details?.phone
                                  ?.mobile_number,
                                ticketDetails?.address_details?.locality,
                                ticketDetails?.address_details?.city,
                                ticketDetails?.specific_requirement,
                                ticketDetails?.address_details?.pincode
                              )
                            }
                          >
                            <WhatsAppIcon
                              sx={{
                                color: "green",
                                fontSize: 30,
                                padding: "1px",
                              }}
                            />
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() =>
                            handleOpenRemarkModal(
                              item?.ticket_obj_id?._id,
                              item?.center_obj_id?._id,
                              item?.remarks?.technician_message,
                              item?.remarks?.onboarding_team_name
                            )
                          }
                          variant="outlined"
                          sx={{
                            backgroundColor: item?.remarks?.technician_message
                              ? "green"
                              : "blue",
                            color: "white",
                            "&:hover": {
                              color: "black",
                            },
                          }}
                        >
                          Remark
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() =>
                            handleEditCenter(item?.center_obj_id?._id)
                          }
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
              )}

              {broadCastList?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={12}>No broadcast given</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Item>
      <Modal
        open={refundModalOpen}
        onClose={handleCloseRefundModal}
        centerObjId={centerObjIdd}
        ticketObjId={ticketObjId}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            p: 2,
            width: 400,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            Refund Details
          </Typography>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Refund Type</InputLabel>
            <Select
              value={refundType}
              onChange={(e) => setRefundType(e.target.value)}
              fullWidth
            >
              <MenuItem value="Refund">Refund</MenuItem>
              <MenuItem value="Penalty">Penalty</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            label="Amount"
            value={refundAmount}
            onChange={(e) => setRefundAmount(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Remarks"
            value={refundRemarks}
            onChange={(e) => setRefundRemarks(e.target.value)}
            sx={{ mt: 2 }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              mt: 2,
            }}
          >
            <Button
              onClick={handleRefundSubmit}
              variant="contained"
              color="primary"
              sx={{ mb: 1 }}
            >
              Submit
            </Button>
            <Button
              onClick={handleCloseRefundModal}
              variant="outlined"
              color="secondary"
              sx={{ mb: 1 }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={remarkModalOpen}
        onClose={handleCloseRemarkModal}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            p: 2,
            width: 300,
            height: 300,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            Add Remark
          </Typography>
          <textarea
            value={remarkValue}
            onChange={(e) => setRemarkValue(e.target.value)}
            style={{
              width: "100%",
              height: "20%",
              resize: "none",
              marginBottom: 10,
            }}
          />
          <Button
            onClick={() => handleSaveRemark(ticketId, centerObjId)}
            variant="contained"
            color="primary"
            style={{ alignSelf: "center" }}
          >
            Save Remark
          </Button>
          <Box sx={{ marginTop: 4, border: "1px solid black", padding: 2 }}>
            <Typography variant="body2" component="div">
              {technicianMessage ? technicianMessage : "No remarks available"} -
              <span style={{ fontWeight: "bold", color: "red" }}>{name}</span>
            </Typography>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={cancelModalOpen}
        onClose={handleCloseCancelModal}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            p: 2,
            width: 500,
            height: 300,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            Why Cancelling?
          </Typography>
          <Typography sx={{ textAlign: "center", mb: 2 }}>
            Enter Remarks
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            sx={{ mb: 2 }}
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              onClick={() =>
                CancelTicket(
                  ticketId,
                  globalState?.adminDetails?.user_name,
                  remarks
                )
              }
              variant="contained"
              color="primary"
              sx={{ mb: 1, marginTop: "20px" }}
            >
              Yes, Cancel
            </Button>
            <Button
              onClick={handleCloseCancelModal}
              variant="outlined"
              color="secondary"
              sx={{ mb: 1, marginTop: "20px" }}
            >
              No, Go Back
            </Button>
          </Box>
        </Box>
      </Modal>

      <Snackbar
        open={toast.open}
        autoHideDuration={toast.duration}
        onClose={() => showDialog(false, toast.type, "")}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => showDialog(false, toast.type, "")}
          severity={toast.type}
          sx={{ width: "100%" }}
        >
          {toast.msg}
        </Alert>
      </Snackbar>
    </Box>
  );
}
