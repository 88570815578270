import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SearchBar from "material-ui-search-bar";
import Button from "@mui/material/Button";
import axios from "axios";
import CenterEditView from "../../components/AdminApi/CenterEditView";
import {
  Alert,
  FormControlLabel,
  FormGroup,
  Snackbar,
  Switch,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ViewEditActions from "../../components/AdminApi/CenterEditViewAction";
import { Grid } from "@mui/material";
import { MenuItem } from "@mui/material";
import { Select } from "@mui/material";
import { InputLabel } from "@mui/material";
import { FormControl } from "@mui/material";
import { Modal } from "@mui/material";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../components/config/config";

// Auth modal code
const stylesCss = {
  inputBox: {
    width: "100%",
    marginBottom: "10px",
  },
  gridStyle: {
    width: "80%",
    margin: "auto",
  },
  paddingInnerGrids: {
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingTop: "10px",
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const CustomSwitch = withStyles({
  switchBase: {
    color: "#303F9F",
    "&$checked": {
      color: "#303F9F",
    },
    "&$checked + $track": {
      backgroundColor: "#303F9F",
    },
  },
  checked: {},
  track: {},
})(Switch);

const EditCenter = () => {
  const history = useHistory();
  const classes = useStyles();

  // SnackBar
  const [snackBarValues, setSnackBarValues] = useState({});

  const [centersList, setCentersList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [selectedCenterId, setSelectCenterId] = useState("");
  const [triggerEffect, setTriggerEffect] = useState(false);

  const [centersSelected, setCentersSelected] = React.useState([]);
  // Modal Code ⬇️
  const [selectedData, setSelectedData] = useState(null);
  const [isVerificationPopupOpen, setVerfiactionPopUp] = useState(false);
  const [isAuthorizedForPopupOpen, setAuthorizedForPopupOpen] = useState(false);

  const toggleAuthorizedForPopup = (centerId) => {
    console.log("centerId Data", centerId);
    setSelectCenterId(centerId);

    if (centerId) {
      setAuthorizedForPopupOpen(!isAuthorizedForPopupOpen);
    }
  };

  //   Client List data
  useEffect(() => {
    const fetchClientList = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/admin/get-all-clients/`);
        setClientList(response?.data?.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchClientList();
  }, []);

  // Update the client code
  const saveAuthorizedCenters = () => {
    let payload = {
      center_obj_id: selectedCenterId,
      clients_ids_list: [...centersSelected],
    };

    axios
      .post(`${BASE_URL}/admin/update-center`, payload)
      .then((res) => {
        //   this.showDialog( "success", "Center updated successfully");
        setSnackBarValues({
          open: true,
          duration: 3000,
          type: "success",
          msg: "Center Updated",
        });

        setTimeout(() => {
          setAuthorizedForPopupOpen(false);
          const fetchCentersList = async () => {
            try {
              const response = await axios.get(
                `${BASE_URL}admin/get-all-centers?phone_number=${searched}`
              );
              setCentersList(response.data);
            } catch (error) {
              console.error(error);
            }
          };
          fetchCentersList();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        setSnackBarValues({
          open: true,
          duration: 3000,
          type: "error",
          msg: { err },
        });
      });
  };
  console.log("centersList", centersList);

  const toggleVerificationDetailsPopup = (centerData) => {
    console.log("Center Data", centerData);
    setSelectedData(centerData);

    if (centerData) {
      setVerfiactionPopUp(!isVerificationPopupOpen);
    }
  };
  console.warn("selected data", selectedData);

  const originalRows = [
    { name: "Pizza", calories: 200, fat: 6.0, carbs: 24, protein: 4.0 },
    { name: "Hot Dog", calories: 300, fat: 6.0, carbs: 24, protein: 4.0 },
    { name: "Burger", calories: 400, fat: 6.0, carbs: 24, protein: 4.0 },
    { name: "Hamburger", calories: 500, fat: 6.0, carbs: 24, protein: 4.0 },
    { name: "Fries", calories: 600, fat: 6.0, carbs: 24, protein: 4.0 },
    { name: "Ice Cream", calories: 700, fat: 6.0, carbs: 24, protein: 4.0 },
  ];

  const [rows, setRows] = useState(originalRows);
  const [searched, setSearched] = useState("");

  const requestSearch = (searchedVal) => {
    const filteredRows = originalRows.filter((row) => {
      return row.name.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  useEffect(() => {
    const fetchCentersList = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}admin/get-all-centers?phone_number=${searched}`
        );
        setCentersList(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCentersList();
  }, [searched]);

  console.log("clientList", clientList);

  // console.log("search", searched);
  // console.log("centersList", centersList.data);

  // Auth modal code
  const handleChange = (event) => {
    let value = event.target.value;
    setCentersSelected(value);
    // console.log("jj",value);
  };
  // console.warn("centersSelected",centersSelected);

  // Edit center
  const redirectToEditCenter = (id) => {
    history.push("/add-center?edit=" + id);
  };

  const closeDialog = (open = false) => {
    setSnackBarValues({
      ...snackBarValues,
      open: open,
      msg: "",
      duration: 5000,
      type: "",
    });
  };

  return (
    <>
      {/* alert and snackbar */}
      {snackBarValues.open && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={snackBarValues.open}
          autoHideDuration={snackBarValues.duration}
          onClose={closeDialog}
        >
          <Alert
            onClose={closeDialog}
            severity={snackBarValues.type}
            sx={{ width: "100%" }}
          >
            {snackBarValues.msg}
          </Alert>
        </Snackbar>
      )}

      <Paper>
        <SearchBar
          placeholder="Enter Phone Number...."
          value={searched}
          onChange={(searchVal) => setSearched(searchVal)}
          onCancelSearch={() => cancelSearch()}
          inputProps={{ type: "number" }}
        />

        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Phone</TableCell>
                <TableCell align="right">QR Code</TableCell>
                <TableCell align="right">Primary Pincode</TableCell>
                <TableCell align="right">Secondary Pincode&nbsp;(g)</TableCell>
                <TableCell align="right">Primary Skill</TableCell>
                <TableCell align="right">Secondary Skill</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Created At</TableCell>
                <TableCell align="right">Onboarded By</TableCell>
                <TableCell align="right">Authorized For Clients</TableCell>
                <TableCell align="right">Total ticket closed</TableCell>
                <TableCell align="right">Verified</TableCell>
                <TableCell align="right">View Verification Details</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searched > 3 &&
                centersList?.data?.map((row, id) => (
                  // console.log("pan id", row?.document_details?.pan_card_document),
                  // console.log("adhar front", row?.document_details?.aadhar_card_document?.front_side),
                  // console.log("pan id", row?.document_details?.aadhar_card_document?.back_side),

                  <TableRow key={id}>
                    <TableCell align="right">{row?.center_name}</TableCell>
                    <TableCell align="right">
                      {row?.personal_details?.phone?.mobile_number}
                    </TableCell>
                    <TableCell align="right">
                      {row?.qr_details?.qr_id}
                    </TableCell>
                    <TableCell align="right">
                      {row?.qr_details?.qrStatus}
                    </TableCell>
                    <TableCell align="right">
                      {row?.address_details?.pincode}
                    </TableCell>
                    <TableCell align="right">
                      {row?.services?.primary_services?.service_name}
                    </TableCell>
                    <TableCell align="right">{}</TableCell>
                    <TableCell align="right">{row?.is_active}</TableCell>
                    <TableCell align="right">{row?.createdAt}</TableCell>
                    <TableCell align="right">{}</TableCell>
                    <TableCell align="right">{}</TableCell>
                    <TableCell align="right">
                      {row?.count_details?.closed_ticket_count}
                    </TableCell>
                    <TableCell align="right">
                      {row?.count_details?.closed_ticket_count}
                    </TableCell>

                    <TableCell align="right">
                      {" "}
                      <FormGroup>
                        <FormControlLabel
                          control={<CustomSwitch color="primary" />}
                        />
                      </FormGroup>{" "}
                    </TableCell>

                    <TableCell align="right">
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          width: "200px",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <Button
                          className="text-transform-none"
                          variant="contained"
                          style={{
                            backgroundColor: "#303F9F",
                            color: "white",
                            padding: "5px 9px",
                          }}
                          onClick={() => redirectToEditCenter(row._id)}
                        >
                          Edit
                        </Button>
                      </div>
                    </TableCell>
                    {/* Modal auth Component ⬇️ */}
                    <Modal
                      open={isAuthorizedForPopupOpen}
                      onClose={toggleAuthorizedForPopup}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Authorized Center For Clients
                        </Typography>
                        <Grid style={stylesCss.paddingInnerGrids} item md={6}>
                          <FormControl
                            md={6}
                            style={stylesCss.inputBox}
                            sx={{ minWidth: 120 }}
                          >
                            <InputLabel id="demo-simple-select-helper-label">
                              Authorized For
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              label="Authorized For"
                              name="centersSelected"
                              onChange={handleChange}
                              value={centersSelected}
                              multiple
                              // error={isFormValid && !centersSelected}
                            >
                              {clientList?.map((ite) => {
                                return (
                                  <MenuItem key={ite._id} value={ite._id}>
                                    {ite.client_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid style={stylesCss.paddingInnerGrids} item>
                          <Button
                            variant="contained"
                            onClick={() =>
                              saveAuthorizedCenters(centersSelected)
                            }
                            disabled={!centersSelected}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Box>
                    </Modal>

                    <CenterEditView
                      selectData={selectedData}
                      isVerificationPopupOpen={isVerificationPopupOpen}
                      toggleVerificationDetailsPopup={
                        toggleVerificationDetailsPopup
                      }
                    />
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default EditCenter;
