import React, { useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Button,
  Alert,
} from "@mui/material";
import CenterRegistrationApi from "../components/centerRegistration/Api/api";
import axios from "axios";
import { BASE_URL } from "../components/config/config";
import Onit from "../components/ServicesStatus/onitLogo.png";
import "./PublicPickDropQr.css";

function PublicPickDropQr() {
  const [centerDetails, setCenterDetails] = useState({});
  const [customerName, setCustomerName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [pickLocation, setPickLocation] = useState("");
  const [dropLocation, setDropLocation] = useState("");
  const [amount, setAmount] = useState(10);
  const [modeOfPayment, setModeOfPayment] = useState("cash");
  const [pincode, setPincode] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [fieldErrors, setFieldErrors] = useState({
    customerName: false,
    contactNo: false,
    pickLocation: false,
    dropLocation: false,
    amount: false,
    modeOfPayment: false,
    pincode: false,
  });

  useEffect(() => {
    let qrId = window.location.hash.split("/")[2];
    console.log(qrId, "qr id");

    getCenterByQrid(qrId);
  }, []);

  const getCenterByQrid = (qrId) => {
    let payload = {
      qr_id: qrId,
    };

    new CenterRegistrationApi()
      .getCenterByQrId(payload)
      .then((res) => {
        setCenterDetails(res.data.doesCenterExists);
        setPincode(res.data.doesCenterExists?.address_details?.pincode || "");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Reset field errors
    setFieldErrors({
      customerName: !customerName,
      contactNo: !contactNo || !/^\d{10}$/.test(contactNo),
      pickLocation: !pickLocation,
      dropLocation: !dropLocation,
      amount: !amount,
      modeOfPayment: !modeOfPayment,
      pincode: !pincode,
    });

    if (
      !customerName ||
      !contactNo ||
      !/^\d{10}$/.test(contactNo) ||
      !pickLocation ||
      !dropLocation ||
      !amount ||
      !modeOfPayment ||
      !pincode
    ) {
      setError("Please fill out all fields correctly.");
      setTimeout(() => setError(""), 10000);
      return;
    }
    if (!/^\d{10}$/.test(contactNo)) {
      setError("Please fill out Contact No. Properly");
      setTimeout(() => setError(""), 10000);
      return;
    }

    const payload = {
      service_provided_for: centerDetails?.services?.primary_services?.[0],
      vehicleId: centerDetails?.vehicleId,
      pick_location: pickLocation,
      drop_location: dropLocation,
      personal_details: {
        phone: {
          country_code: "91",
          mobile_number: contactNo,
        },
        name: customerName,
      },
      pincode: pincode,
      city: centerDetails?.address_details?.city,
      state: centerDetails?.address_details?.state,
      ticket_price: amount,
      mode_of_payment: modeOfPayment,
      center_obj_id: centerDetails?._id,
    };

    axios
      .post(`${BASE_URL}technicianApp/ticket-createfor-erikshaw`, payload)
      .then((response) => {
        console.log("Success:", response.data);
        setError("");
        setSuccess("Responded Successfully"); // Clear error message on success

        setCustomerName("");
        setContactNo("");
        setPickLocation("");
        setDropLocation("");
        setAmount(10);
        setModeOfPayment("cash");
        setPincode("");
        setFieldErrors({
          customerName: false,
          contactNo: false,
          pickLocation: false,
          dropLocation: false,
          amount: false,
          modeOfPayment: false,
          pincode: false,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <div className="mobile-view">
        <img src={Onit} alt="Onit Logo" />
        <div>Have a Safe Journey!!</div>
      </div>
      <div className="web-view">
        <img src={Onit} alt="Onit Logo" />
        <div>Have a Safe Journey!!</div>
      </div>
      <Box component="form" sx={{ p: 2, backgroundColor: "white" }}>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        <TextField
          label="Name of Customer"
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
          fullWidth
          margin="normal"
          required
          error={fieldErrors.customerName}
        />
        <TextField
          label="Contact No."
          value={contactNo}
          onChange={(e) => setContactNo(e.target.value)}
          fullWidth
          margin="normal"
          required
          error={fieldErrors.contactNo}
          inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
        />
        <TextField
          label="Pincode"
          value={pincode}
          onChange={(e) => setPincode(e.target.value)}
          fullWidth
          margin="normal"
          required
          error={fieldErrors.pincode}
        />
        <TextField
          label="Pick Location"
          value={pickLocation}
          onChange={(e) => setPickLocation(e.target.value)}
          fullWidth
          margin="normal"
          required
          error={fieldErrors.pickLocation}
        />
        <TextField
          label="Drop Location"
          value={dropLocation}
          onChange={(e) => setDropLocation(e.target.value)}
          fullWidth
          margin="normal"
          required
          error={fieldErrors.dropLocation}
        />

        <FormControl
          fullWidth
          margin="normal"
          required
          error={fieldErrors.amount}
        >
          <InputLabel id="amount-label">Amount</InputLabel>
          <Select
            labelId="amount-label"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            label="Amount"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={40}>40</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          margin="normal"
          required
          error={fieldErrors.modeOfPayment}
        >
          <InputLabel id="mode-of-payment-label">Mode of Payment</InputLabel>
          <Select
            labelId="mode-of-payment-label"
            value={modeOfPayment}
            onChange={(e) => setModeOfPayment(e.target.value)}
            label="Mode of Payment"
          >
            <MenuItem value="cash">Cash</MenuItem>
            <MenuItem value="online">Online</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="E-rickshaw Driver Name"
          value={centerDetails.center_name || ""}
          fullWidth
          margin="normal"
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          label="QR-ID Driver"
          value={centerDetails.qr_details?.qr_id || ""}
          fullWidth
          margin="normal"
          InputProps={{
            readOnly: true,
          }}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{ width: "250px" }}
          >
            Submit
          </Button>
        </div>
      </Box>
    </div>
  );
}

export default PublicPickDropQr;
