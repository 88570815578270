// export const BASE_URL = "https://assa.herokuapp.com/";
// export const BASE_URL = "https://api.onit.fit/"
// export const BASE_URL_UI = "http://localhost:3000/"
// export const BASE_URL_UI = "https://app.onit.services/"

//export const BASE_URL = "https://api.onitservices.in/";
export const BASE_URL = "https://api.onit.services/";
//  export const BASE_URL = "https://test.onit.services/";
//  export const BASE_URL = "https://localhost:5000/";

export const RAZORPAY_KEY = "rzp_test_xbtU9g8sjSB3vN"; // Testing key
// export const RAZORPAY_KEY = "rzp_live_yr00EgqO9pvjDt";

export const PAYMENT_NAME = "onit";
export const PAYMENT_DESCRIPTION = "Ticket acceptance";
