import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import DeleteForever from "@mui/icons-material/DeleteForever";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Typography from "@mui/joy/Typography";

export default function DeleteAlertDialogModal({
  openAlert,
  handleAlertClose,
  handleDeleteTicket,
  Heading,
}) {
  const [open, setOpen] = React.useState(true);
  console.log(Heading);
  return (
    <React.Fragment>
      <Modal open={openAlert} onClose={handleAlertClose}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          aria-labelledby="alert-dialog-modal-title"
          aria-describedby="alert-dialog-modal-description"
        >
          <Typography
            id="alert-dialog-modal-title"
            component="h1"
            style={{ marginBottom: "10px" }}
            startDecorator={<WarningRoundedIcon style={{ color: "red" }} />}
          >
            Confirmation
          </Typography>
          <Divider />
          <Typography
            style={{ marginTop: "10px" }}
            id="alert-dialog-modal-description"
            textColor="text.tertiary"
          >
            Are You Sure You Want To Delete This {Heading}!
          </Typography>
          <Box
            sx={{ display: "flex", gap: 1, justifyContent: "flex-end", pt: 2 }}
          >
            <Button variant="plain" color="neutral" onClick={handleAlertClose}>
              Cancel
            </Button>
            <Button
              variant="solid"
              style={{ background: "#D21312" }}
              onClick={handleDeleteTicket}
            >
              Delete
            </Button>
          </Box>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
