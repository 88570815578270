import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../config/config";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import moment from "moment";
function NotificationLog() {
  const [notificationLogs, setNotificationLogs] = useState([]);

  const getAllNotificationLogs = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}admin/get-all-notification-log`
      );
      setNotificationLogs(response.data.data);
    } catch (error) {
      console.error("Error fetching notification logs:", error);
    }
  };

  useEffect(() => {
    getAllNotificationLogs();
  }, []);

  return (
    <div>
      <h1>History</h1>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Sent At</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Sent To</TableCell>
              <TableCell>Sent By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notificationLogs.map((notification) => (
              <TableRow key={notification._id}>
                <TableCell>
                  {moment(notification.createdAt).format(
                    "DD/MM/YYYY HH:mm:ss "
                  )}
                </TableCell>
                <TableCell>{notification?.notification_id?.title}</TableCell>
                <TableCell>
                  {notification?.notification_id?.discription}
                </TableCell>
                <TableCell>{notification?.userType}</TableCell>
                <TableCell>{notification.sentBy}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default NotificationLog;
