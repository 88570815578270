import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../components/config/config";
import MUIDataTable from "mui-datatables";
import BasicDateRangePicker from "../../components/common/datepicker";
import { Grid } from "@mui/material"; // Import Grid from Material-UI

const parseTicketData = (data) => {
  return data.map((ticket) => ({
    createdAt: ticket.createdAt,
    ticket_id: ticket.ticket_id,
    ticket_status: ticket.ticket_status,
    customer_name: ticket.personal_details?.name,
    pick_location: ticket.pickupAddress?.pick_location,
    drop_location: ticket.dropAddress?.drop_location,
    pincode: ticket.pickupAddress?.pincode,
    amount: ticket.ticket_price,
    mode_of_payment: ticket.mode_of_payment,
    customer_mobile: ticket?.personal_details?.phone?.mobile_number,
    driver_name: ticket?.center_obj_id?.center_name,
    qrId: ticket?.center_obj_id?.qr_details?.qr_id,
  }));
};

function ERickshawTicket() {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    refreshData();
  }, []);

  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);

  const fetchTickets = async (payload = {}) => {
    startLoading();
    try {
      const response = await axios.get(
        `${BASE_URL}technicianApp/get-all-erikshaw-ticket`,
        {
          params: payload,
        }
      );
      const parsedData = parseTicketData(response.data.data);
      setTickets(parsedData);
    } catch (error) {
      console.error("Error fetching tickets:", error);
    } finally {
      stopLoading();
    }
  };

  const onClickFilter = (value) => {
    const payload = {
      start_date: value[0],
      end_date: value[1],
    };
    fetchTickets(payload);
  };

  const refreshData = () => {
    fetchTickets();
  };

  const columns = [
    {
      name: "createdAt",
      label: "Created At",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return new Date(value).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true,
          });
        },
      },
    },
    {
      name: "ticket_id",
      label: "Request ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "ticket_status",
      label: "Request Status",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "customer_name",
      label: "Customer Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "customer_mobile",
      label: "Customer Mobile",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "pick_location",
      label: "Pick Location",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "drop_location",
      label: "Drop Location",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "pincode",
      label: "Pincode",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "driver_name",
      label: "Driver Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "qrId",
      label: "QR Id",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "mode_of_payment",
      label: "Mode Of Payment",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  const stylesCss = {
    paddingInnerGrids: {
      padding: "10px",
    },
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item style={stylesCss.paddingInnerGrids} md={6}>
          <BasicDateRangePicker
            onClickFilter={onClickFilter}
            refreshData={refreshData}
          />
        </Grid>
      </Grid>
      <MUIDataTable
        title={"E-Rickshaw Requests"}
        data={tickets}
        columns={columns}
        options={options}
      />
    </div>
  );
}

export default ERickshawTicket;
