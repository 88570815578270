import React, { useEffect, useRef, useState } from "react";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  IconButton,
  CircularProgress,
  Modal,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import axios from "axios";
import { BASE_URL } from "../../components/config/config";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PanToolIcon from "@mui/icons-material/PanTool";
import DeleteIcon from "@mui/icons-material/Delete";

// Sortable item component
export const SortableItem = ({
  _id,
  banner,
  handleImageClick,
  toggleStatus,
  deleteBanner,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: _id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    position: "relative", // Required for label positioning
  };

  return (
    <Card
      ref={setNodeRef}
      style={style}
      sx={{
        width: 300,
        height: 200,
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        position: "relative", // Required for label positioning
      }}
    >
      {/* Image */}
      <CardMedia
        component="img"
        alt="Image is not available"
        image={banner?.image}
        sx={{ width: "100%", height: "100%", objectFit: "cover" }}
        onClick={() => handleImageClick(banner?.image)}
      />

      {/* Active/Inactive Label */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "absolute",
          top: 8,
          right: 8,
          width: "45%",
          gap: "10px", // Space between icon and text
        }}
      >
        <Box
          sx={{
            backgroundColor: banner?.isActive ? "green" : "red",
            color: "white",
            borderRadius: "4px",
            padding: "2px 6px",
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
            gap: "4px", // Space between icon and text
            cursor: "pointer",
            userSelect: "none",
            width: "80%",
            justifyContent: "center",
          }}
          onClick={(e) => {
            e.stopPropagation(); // Prevent click bubbling to drag
            toggleStatus(_id, !banner.isActive); // Toggle status
          }}
        >
          {banner?.isActive ? (
            <VisibilityIcon fontSize="small" />
          ) : (
            <VisibilityOffIcon fontSize="small" />
          )}
          <Typography variant="body1">
            {banner?.isActive ? "Active" : "Inactive"}
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: "#ff0000",
            p: "2px 6px",
            alignItems: "center",
            borderRadius: "4px",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation(); // Prevent click bubbling to drag
            deleteBanner(_id); // Toggle status
          }}
        >
          <DeleteIcon fontSize="small" htmlColor="#ffffff" />
        </Box>
      </Box>

      {/* Sequence Order */}
      <Box
        sx={{
          position: "absolute",
          width: "10%",
          backgroundColor: "white",
          left: 8,
          bottom: 8,
          color: "black",
          fontWeight: "bold",
          fontSize: "12px",
          textAlign: "center",
          borderRadius: "4px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Typography variant="body1">{banner?.sequenceOrder}</Typography>
      </Box>

      <Box
        {...attributes}
        {...listeners}
        sx={{
          position: "absolute",
          width: "10%",
          backgroundColor: "white",
          right: 8,
          bottom: 8,
          color: "black",
          fontWeight: "bold",
          fontSize: "12px",
          textAlign: "center",
          borderRadius: "4px",
          padding: "4px",
          cursor: "grab",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <PanToolIcon fontSize="small" />
      </Box>
    </Card>
  );
};

const BannerPage = () => {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(false); // For showing loader during upload
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const fileInputRef = useRef(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedImage(null);
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const updatedBanners = arrayMove(
        banners,
        banners.findIndex((banner) => banner._id === active.id),
        banners.findIndex((banner) => banner._id === over.id)
      );

      setBanners(updatedBanners);

      // Prepare payload for API
      const payload = {
        bannerId: updatedBanners.map((banner) => banner._id),
      };

      try {
        // Call the API to update sequence
        const response = await axios.post(
          `${BASE_URL}admin/updateSequenceofBannerImage`,
          payload
        );

        if (response.status === 200) {
          getAllBanner();
          console.log("Sequence updated successfully");
        } else {
          console.error("Failed to update sequence:", response?.data?.message);
        }
      } catch (error) {
        console.error("Error updating sequence:", error);
      }
    }
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const toggleBannerStatus = async (bannerId, isActive) => {
    try {
      const response = await axios.post(
        `${BASE_URL}admin/updateBannerImageStatus`,
        {
          bannerId,
          isActive,
        }
      );

      if (response.status === 200) {
        setBanners((prevBanners) =>
          prevBanners.map((banner) =>
            banner._id === bannerId ? { ...banner, isActive } : banner
          )
        );
        alert(`Banner status updated to ${isActive ? "Active" : "Inactive"}`);
      } else {
        alert("Failed to update banner status.");
      }
    } catch (error) {
      console.error("Error updating banner status:", error);
      alert("An error occurred while updating the banner status.");
    }
  };

  const getAllBanner = async () => {
    try {
      const response = await axios.get(`${BASE_URL}admin/getAllBannerImage`);
      setBanners(
        response?.data?.data?.sort((a, b) => a.sequenceOrder - b.sequenceOrder)
      ); // Assuming the response contains a data array with banners
    } catch (error) {
      console.error("Error fetching banners:", error);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("bannerImage", file);

    setLoading(true); // Show loader while uploading

    try {
      const response = await axios.post(
        `${BASE_URL}admin/uploadBannerImage`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        const newBanner = {
          _id: response.data._id, // Assuming the backend returns an `_id`
          image: URL.createObjectURL(file), // Temporary URL for preview
          sequenceOrder: banners.length + 1, // Set sequence based on order
        };
        setBanners((prevBanners) => [...prevBanners, newBanner]);
        alert("Banner uploaded successfully!");
      } else {
        alert("Failed to upload banner. Please try again.");
      }
    } catch (error) {
      console.error("Error uploading banner:", error);
      alert("An error occurred while uploading the banner.");
    } finally {
      setLoading(false); // Hide loader after upload
    }
  };

  const deleteBanner = async (bannerId) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}admin/deleteBannerImage/${bannerId}`
      );

      if (response.status === 200) {
        // Remove the deleted banner from the state
        setBanners((prevBanners) =>
          prevBanners.filter((banner) => banner._id !== bannerId)
        );
        alert("Banner deleted successfully!");
      } else {
        console.error("Failed to delete banner:", response?.data?.message);
        alert("Failed to delete banner. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting banner:", error);
      alert("An error occurred while deleting the banner.");
    }
  };

  useEffect(() => {
    getAllBanner();
  }, []);

  return (
    <>
      <Typography
        variant="h4"
        color={"InfoText"}
        style={{ textAlign: "center", marginTop: "1rem", marginBottom: "1rem" }}
      >
        Banner for consumer app
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          p: 2,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={banners.map((banner) => banner._id)}
            strategy={verticalListSortingStrategy}
          >
            {banners.map((banner) => (
              <SortableItem
                key={banner._id}
                _id={banner._id}
                banner={banner}
                handleImageClick={handleImageClick}
                toggleStatus={toggleBannerStatus}
                deleteBanner={deleteBanner}
              />
            ))}
          </SortableContext>
        </DndContext>

        <Card
          sx={{
            width: 300,
            height: 200,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            border: "2px dashed #ccc",
            cursor: "pointer",
            transition: "transform 0.2s",
            "&:hover": {
              transform: "scale(1.05)",
            },
          }}
          onClick={handleUploadClick}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <IconButton>
                <AddCircleOutlineIcon sx={{ fontSize: 48, color: "#1976d2" }} />
              </IconButton>
              <Typography variant="body1" sx={{ color: "#666", mt: 1 }}>
                Upload Banner
              </Typography>
            </>
          )}
        </Card>

        <input
          type="file"
          accept="image/png, image/jpeg"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />

        <Modal
          open={openModal}
          onClose={handleModalClose}
          aria-labelledby="banner-modal-title"
          aria-describedby="banner-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 2,
              borderRadius: 2,
              maxWidth: "90%",
              maxHeight: "90%",
              overflow: "auto",
            }}
          >
            {selectedImage ? (
              <img
                src={selectedImage}
                alt="Banner Preview"
                style={{ width: "100%", height: "auto" }}
              />
            ) : (
              <Typography variant="body2" color="textSecondary">
                No image selected
              </Typography>
            )}
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default BannerPage;
