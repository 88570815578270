import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import RazorpayCheckout from "react-razorpay";
import "../Styles/style.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Theme } from "@mui/material/styles";
import VerifiedIcon from "@mui/icons-material/Verified";
import List from "@mui/material/List";
import { Link, Redirect } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import {
  Alert,
  Avatar,
  InputAdornment,
  Snackbar,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { BASE_URL } from "../components/config/config";
// Razor Pay
import {
  RAZORPAY_KEY,
  PAYMENT_NAME,
  PAYMENT_DESCRIPTION,
} from "../components/config/config";

import CenterRegistrationApi from "../components/centerRegistration/Api/api";
import CenterComponentApi from "../components/centerComponent/api/api";
import { IndianStates } from "../assets/IndianStates";
import axios from "axios";
import CopyButton from "../components/ServicesStatus/ClipbaordButton";
import RazorPayment from "./RazorPayPayment";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

// Modal css
const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid gray",
  boxShadow: 44,
  p: 4,
  borderRadius: 4,
  color: "black",
};

//   List render
const listStyle = {
  width: "100%",
  // maxWidth: 360,
  bgcolor: "background.paper",
};

const stylesCss = {
  inputBox: {
    width: "100%",
    marginBottom: "10px",
  },
  gridStyle: {
    width: "80%",
    margin: "auto",
  },
  paddingInnerGrids: {
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingTop: "10px",
  },
};

const AVAILABLE_TIME_PREFERENCES = [
  "IMMEDIATELY",
  "WITHIN_24_HOURS",
  "SPECIFIC_DATE_AND_TIME",
];

class PublicTicketBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceRequired: "",
      specificRequirement: "",
      name: "",
      phoneNumber: "",
      alternatePhoneNumber: "",
      houseNumber: "",
      locality: "",
      pincode: "",
      city: "",
      state: "",
      country: "INDIA",
      timePreference: "",
      offerCode: "ONIT2025",
      specific_date_time: new Date(),

      toast: {
        open: false,
        msg: "",
        duration: 10000,
        type: "",
      },
      isFormValid: false,
      servicesList: [],
      TicketModalOpen: false,
      ticketModalData: [],
      isRegistering: false,
      tickectId: "",
      verifyOtp: false,
      otp: "",
      paymentStatus: "",
      needToPay: false,
      razorpayinitiate: false,
    };
  }

  componentDidMount() {
    this.getAllServicesExists();
    // this.handleInitiatePayment();
  }

  getAllServicesExists() {
    new CenterRegistrationApi()
      .getAllActiveServices()
      .then((res) => {
        this.setState({
          servicesList: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  onChangeSpecificDateAndTime(newValue) {
    this.setState({ specific_date_time: newValue });
  }

  showDialog = (open, type, msg) => {
    this.setState({
      toast: {
        open: open,
        msg: msg,
        duration: 10000,
        type: type,
      },
    });
  };

  closeDialog = (open = false) => {
    this.setState({
      toast: {
        open: open,
        msg: "",
        duration: 5000,
        type: "",
      },
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;

    if (name == "pincode") {
      if (value.length <= 6) {
        this.setState({
          pincode: value,
        });
      }

      return;
    }
    this.setState(
      {
        [name]: value,
      },
      () => {
        console.log(this.state);
      }
    );
  };

  handleCreateTickect = async (ress) => {
    const {
      serviceRequired,
      specificRequirement,
      name,
      phoneNumber,
      alternatePhoneNumber,
      houseNumber,
      locality,
      city,
      state,
      country,
      pincode,
      timePreference,
      offerCode,
    } = this.state;
    if (
      !(
        serviceRequired &&
        name &&
        phoneNumber &&
        houseNumber &&
        pincode &&
        timePreference &&
        offerCode &&
        state &&
        city
      )
    ) {
      this.setState({
        isFormValid: true,
      });

      this.showDialog(true, "error", "Please fill in all required details");
      return;
    }

    this.setState({
      isRegistering: true,
    });

    if (this.state.timePreference == "SPECIFIC_DATE_AND_TIME") {
      if (!this.state.specific_date_time) {
        this.showDialog(true, "error", "Please fill in Specific date and time");
        return;
      }
    }

    let payload = {
      service_provided_for: serviceRequired,
      specific_requirement: specificRequirement,
      personal_details: {
        primary_phone: {
          country_code: "+91",
          mobile_number: phoneNumber,
        },
        alternate_phone: {
          country_code: "+91",
          mobile_number: alternatePhoneNumber,
        },
        name: name,
      },
      time_preference: {
        time_preference_type: timePreference,
      },
      offers_applied: {
        offer_code: offerCode,
      },
      address_details: {
        house_number: houseNumber,
        locality: locality,
        city: city,
        state: state,
        pincode: pincode,
        country: country,
      },
      // center_obj_id: "64f50a500c2e9bbc94ed1b39",
      urldata: window.location.href,
      // urldata: "https://app.onit.services/#/booking/INA2021",
    };

    await new CenterComponentApi()
      .PublicTicketBooking(payload)
      .then(async (res) => {
        if (res?.message === "Please complete the payment process") {
          this.handleInitiatePayment();
          this.setState({ tickectId: res?.data?._id });
        } else {
          this.setState({ ticketModalData: res?.data });
          alert("Your request created successfully");
          this.setState({ TicketModalOpen: true });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isRegistering: false,
        });
        this.showDialog(true, "error", err?.response?.data?.message);
      });
  };

  handleSendOtp = async () => {
    const {
      serviceRequired,
      specificRequirement,
      name,
      phoneNumber,
      alternatePhoneNumber,
      houseNumber,
      locality,
      city,
      state,
      country,
      pincode,
      timePreference,
      offerCode,
    } = this.state;
    if (
      !(
        serviceRequired &&
        name &&
        phoneNumber &&
        houseNumber &&
        pincode &&
        timePreference &&
        offerCode &&
        state &&
        city &&
        locality &&
        specificRequirement
      )
    ) {
      this.setState({
        isFormValid: true,
      });

      this.showDialog(true, "error", "Please fill in all required details");
      return;
    }
    try {
      const _data = {
        country_code: "+91",
        mobile_number: this.state.phoneNumber,
      };
      await axios({
        method: "post",
        url: `${BASE_URL}consumerAppAppRoute/sent-otp-by-web`,

        data: _data,
      }).then((res) => this.setState({ verifyOtp: true }));
    } catch (error) {
      console.log(error);
    }
  };

  handleVerifyOtp = async () => {
    try {
      const _data = {
        country_code: "+91",
        mobile_number: this.state.phoneNumber,
        otp: parseInt(this.state.otp),
      };
      await axios({
        method: "post",
        url: `${BASE_URL}consumerAppAppRoute/verify-otp-by-web`,

        data: _data,
      }).then((res) => {
        this.setState({ verifyOtp: false });
        this.handleCheckUserExist();
      });
    } catch (error) {
      // setOtpSend(true);
      alert("Wrong OTP");
    }
  };

  handleCheckUserExist = async () => {
    try {
      let res1 = await axios.post(
        `${BASE_URL}consumerAppAppRoute/register-consumer-by-web`,
        {
          personal_details: {
            phone: {
              country_code: "+91",
              mobile_number: this.state.phoneNumber,
            },
            name: this.state.name,
          },
          address_details_permanent: {
            pincode: this.state.pincode,
          },
        }
      );

      if (res1?.data?.status === 200) {
        this.handleCreateTickect(res1?.data?.data);
      } else {
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  handleInitiatePayment = () => {
    this.setState({ razorpayinitiate: true });
  };

  handleClose = () => {
    this.setState({
      TicketModalOpen: false,
    });
  };

  render() {
    const {
      toast: snackBarValues,
      isFormValid,
      serviceRequired,
      specificRequirement,
      name,
      phoneNumber,
      alternatePhoneNumber,
      houseNumber,
      locality,
      pincode,
      city,
      state,
      country,
      timePreference,
      offerCode,
      otp,
    } = this.state;
    return (
      <div>
        {this.state.razorpayinitiate === true ? (
          <RazorPayment
            tickectId={this.state.tickectId}
            phonenumber={this.state.phoneNumber}
          />
        ) : null}
        {/* Ticket creation modal code */}

        <Modal
          keepMounted
          open={this.state.TicketModalOpen}
          //   open={false}
          onClose={this.handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%", // Adjust width for smaller screens
              maxWidth: 700,
              bgcolor: "background.paper",
              border: "2px solid gray",
              boxShadow: 24,
              borderRadius: 4,
              color: "black",
              textAlign: "center", // Center align content
              p: { xs: 2, md: 4 }, // Adjust padding for different screen sizes
              "& .MuiTypography-root": {
                mb: 2, // Adjust margin bottom for typography elements
              },
              "& .MuiList-root": {
                width: "100%", // Ensure list takes full width
              },
              "& .MuiListItem-root": {
                display: "block", // Display list items as blocks
              },
              "& .MuiListItem-root + .MuiDivider-root": {
                margin: "8px 0", // Adjust margin between list items and dividers
              },
              "@media (max-width: 600px)": {
                width: "100%", // Adjust width for smaller screens
                maxWidth: "100%", // Adjust max width for smaller screens
                borderRadius: 0, // Remove border radius for smaller screens if needed
                boxShadow: "none", // Remove box shadow for smaller screens if needed
              },
            }}
          >
            <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
              <VerifiedIcon color="success" fontSize="large" />
              Hurray! Your Request has been created
            </Typography>
            <Typography sx={{ mt: 2 }}>
              {/* List content */}
              <List
                sx={{ width: "100%" }}
                component="nav"
                aria-label="mailbox folders"
              >
                <ListItem button>
                  <ListItemText primary="Ticket Id :" />
                  <Typography>
                    {this.state.ticketModalData?.ticket_id}
                  </Typography>
                </ListItem>
                <Divider />
                <ListItem button divider>
                  <ListItemText primary="Ticket Status :" />
                  <Typography>
                    {this.state.ticketModalData?.ticket_status}
                  </Typography>
                </ListItem>
                <ListItem button>
                  <ListItemText primary="Created At :" />
                  <Typography>
                    {this.state.ticketModalData?.createdAt}
                  </Typography>
                </ListItem>
                <Divider />
                <ListItem button>
                  <ListItemText primary="Tracking Link :" />
                  <Typography>
                    <CopyButton
                      webLink={`https://app.onit.services/#/ServiceStatus/?${this.state.ticketModalData?._id}`}
                    />
                    <Link
                      style={{ marginLeft: "17px" }}
                      to={`ServiceStatus/?${this.state.ticketModalData?._id}`}
                    >
                      Click me
                    </Link>
                  </Typography>
                </ListItem>
              </List>
            </Typography>
          </Box>
        </Modal>

        {snackBarValues.open && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackBarValues.open}
            autoHideDuration={snackBarValues.duration}
            onClose={this.closeDialog}
          >
            <Alert
              onClose={this.closeDialog}
              severity={snackBarValues.type}
              sx={{ width: "100%" }}
            >
              {snackBarValues.msg}
            </Alert>
          </Snackbar>
        )}
        <h2 className="text-align-center" style={{ color: "black" }}>
          Namaste !
        </h2>
        <h4 className="text-align-center" style={{ color: "black" }}>
          Please help us understand your need
        </h4>

        <Item>
          <Grid item xs={12} container spacing={1}>
            <Grid
              item
              lg={3}
              sm={6}
              xs={12}
              style={stylesCss.paddingInnerGrids}
            >
              <FormControl md={6} style={stylesCss.inputBox}>
                <InputLabel id="demo-simple-select-helper-label">
                  Service You Required
                </InputLabel>

                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Service You Required"
                  name="serviceRequired"
                  onChange={this.handleChange}
                  value={serviceRequired}
                  error={isFormValid && !serviceRequired}
                >
                  {this.state.servicesList?.map((ite) => {
                    return (
                      <MenuItem value={ite._id}>
                        <Avatar
                          alt={ite?.service_name}
                          src={ite?.image}
                          sx={{ width: 29, height: 29, marginRight: "14px" }}
                        />{" "}
                        {ite.service_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              lg={3}
              sm={6}
              xs={12}
              style={stylesCss.paddingInnerGrids}
              md={6}
            >
              <TextField
                id="demo-helper-text-misaligned"
                label="Specific Requirement - it helps"
                style={stylesCss.inputBox}
                name="specificRequirement"
                value={specificRequirement}
                onChange={this.handleChange}
                error={isFormValid && !specificRequirement}
              />
            </Grid>

            <Grid
              item
              lg={3}
              sm={6}
              xs={12}
              style={stylesCss.paddingInnerGrids}
              md={6}
            >
              <TextField
                id="demo-helper-text-misaligned"
                label="Name (Contact Person)"
                style={stylesCss.inputBox}
                name="name"
                value={name}
                onChange={this.handleChange}
                error={isFormValid && !name}
              />
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xs={12}
              style={stylesCss.paddingInnerGrids}
              md={6}
            >
              <TextField
                id="demo-helper-text-misaligned"
                label="Phone Number"
                style={stylesCss.inputBox}
                name="phoneNumber"
                value={phoneNumber}
                onChange={this.handleChange}
                error={isFormValid && !phoneNumber}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>+91</Typography>
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>

            <Grid
              item
              lg={3}
              sm={6}
              xs={12}
              style={stylesCss.paddingInnerGrids}
            >
              <TextField
                id="demo-helper-text-misaligned"
                label="Alternate Phone Number"
                style={stylesCss.inputBox}
                name="alternatePhoneNumber"
                value={alternatePhoneNumber}
                onChange={this.handleChange}
                // error={isFormValid && !alternatePhoneNumber}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>+91</Typography>
                    </InputAdornment>
                  ),
                }}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid
              item
              lg={3}
              sm={6}
              xs={12}
              style={stylesCss.paddingInnerGrids}
            >
              <TextField
                id="demo-helper-text-misaligned"
                label="House Number / Street"
                style={stylesCss.inputBox}
                name="houseNumber"
                value={houseNumber}
                onChange={this.handleChange}
                error={isFormValid && !houseNumber}
              />
            </Grid>
            <Grid
              style={stylesCss.paddingInnerGrids}
              item
              lg={3}
              sm={6}
              xs={12}
            >
              <TextField
                id="demo-helper-text-misaligned"
                label="Locality"
                style={stylesCss.inputBox}
                name="locality"
                value={locality}
                onChange={this.handleChange}
                error={isFormValid && !locality}
              />
            </Grid>
            <Grid
              style={stylesCss.paddingInnerGrids}
              item
              lg={3}
              sm={6}
              xs={12}
            >
              <TextField
                id="demo-helper-text-misaligned"
                label="Pincode"
                style={stylesCss.inputBox}
                name="pincode"
                type="number"
                value={pincode}
                onChange={this.handleChange}
                error={isFormValid && !pincode}
              />
            </Grid>
            <Grid
              style={stylesCss.paddingInnerGrids}
              item
              lg={3}
              sm={6}
              xs={12}
            >
              <TextField
                id="demo-helper-text-misaligned"
                label="City"
                style={stylesCss.inputBox}
                name="city"
                value={city}
                onChange={this.handleChange}
                error={isFormValid && !city}
              />
            </Grid>
            <Grid
              style={stylesCss.paddingInnerGrids}
              item
              lg={3}
              sm={6}
              xs={12}
            >
              <FormControl
                md={6}
                style={stylesCss.inputBox}
                sx={{ minWidth: 120 }}
              >
                <InputLabel id="demo-simple-select-helper-label">
                  State
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="State"
                  name="state"
                  onChange={this.handleChange}
                  value={state}
                  error={isFormValid && !state}
                >
                  {IndianStates?.map((ite) => {
                    return <MenuItem value={ite.value}>{ite.value}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              style={stylesCss.paddingInnerGrids}
              item
              lg={3}
              sm={6}
              xs={12}
            >
              <TextField
                id="demo-helper-text-misaligned"
                label="Country"
                style={stylesCss.inputBox}
                name="country"
                value={country}
                onChange={this.handleChange}
                error={isFormValid && !country}
                disabled
              />
            </Grid>

            <Grid
              style={stylesCss.paddingInnerGrids}
              item
              lg={3}
              sm={6}
              xs={12}
            >
              <FormControl
                md={6}
                style={stylesCss.inputBox}
                sx={{ minWidth: 120 }}
              >
                <InputLabel id="demo-simple-select-helper-label">
                  Time Preference
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Time Preference"
                  name="timePreference"
                  onChange={this.handleChange}
                  value={timePreference}
                  error={isFormValid && !timePreference}
                >
                  {AVAILABLE_TIME_PREFERENCES?.map((ite) => {
                    return <MenuItem value={ite}>{ite}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            {this.state.timePreference == "SPECIFIC_DATE_AND_TIME" ? (
              <Grid style={stylesCss.paddingInnerGrids} item md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(params) => <TextField {...params} />}
                    label="Select date and time"
                    value={this.state.specific_date_time}
                    onChange={(newValue) => {
                      this.onChangeSpecificDateAndTime(newValue);
                    }}
                    minDate={new Date()}
                    minTime={new Date(0, 0, 0, 8)}
                    maxTime={new Date(0, 0, 0, 20)}
                  />
                </LocalizationProvider>
              </Grid>
            ) : (
              ""
            )}
            <Grid
              style={stylesCss.paddingInnerGrids}
              item
              lg={3}
              sm={6}
              xs={12}
            >
              <TextField
                id="demo-helper-text-misaligned"
                label="Offer Code"
                style={stylesCss.inputBox}
                name="offerCode"
                value={offerCode}
                onChange={this.handleChange}
                error={isFormValid && !offerCode}
              />
            </Grid>
            {this.state.verifyOtp === true ? (
              <>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="demo-helper-text-misaligned"
                    label="OTP"
                    style={{
                      borderColor: "blue",
                      width: "100%",
                      marginBottom: "10px",
                      color: "blue",
                      backgroundColor: "#7DCEA0",
                    }}
                    name="otp"
                    value={otp}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="contained"
                    onClick={() => this.handleVerifyOtp()}
                  >
                    VERIFY OTP
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="contained"
                    onClick={() => this.handleSendOtp()}
                    disabled={this.state.isRegistering}
                  >
                    SUBMIT
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <h2>
                    {this.state.paymentStatus !== ""
                      ? this.state.paymentStatus
                      : ""}
                  </h2>
                </Grid>
              </>
            )}
          </Grid>
        </Item>
      </div>
    );
  }
}

export default PublicTicketBooking;
