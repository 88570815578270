import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import { setLoginDetails } from "../../store/UserStore/action";
import { useSelector, useDispatch } from "react-redux";

import BuildIcon from "@mui/icons-material/Build";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EditIcon from "@mui/icons-material/Edit";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import EngineeringIcon from "@mui/icons-material/Engineering";
import FeedbackIcon from "@mui/icons-material/Feedback";
import PeopleIcon from "@mui/icons-material/People";
import LogoutIcon from "@mui/icons-material/Logout";
import Onit from "../ServicesStatus/onitLogo.png";

const drawerWidth = 240;

const drawerItemsResponsive = [
  { name: "Dashboard", path: "/center-dashboard", icon: <BuildIcon /> },
  {
    name: "Technicians",
    path: "/Technicians",
    icon: <SupervisorAccountIcon />,
  },
  { name: "Tickets", path: "/tickets-center", icon: <PersonAddIcon /> },
  { name: "AddTechnician", path: "/AddTechnician", icon: <EditIcon /> },
  {
    name: "Ticket created by you and broadcasted",
    path: "/ticket-created-by-you",
    icon: <RequestPageIcon />,
  },
  { name: "AddTicket", path: "/AddTicket", icon: <EngineeringIcon /> },
  { name: "New Tickets", path: "/brodcast-requests", icon: <FeedbackIcon /> },
  { name: "Payments", path: "/payments", icon: <PeopleIcon /> },
];

function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const globalState = useSelector((state) => state.userReducer);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onClickLogout = () => {
    dispatch(
      setLoginDetails({
        userDetails: {},
        isUserLoggedIn: false,
        userTokens: { accessToken: "" },
      })
    );
    history.push("/login");
  };

  const drawer = (
    <div style={{ backgroundColor: "#A3E4D7" }}>
      <img
        src={Onit}
        alt="Onit Logo"
        style={{
          width: "70%",
          height: "70px",
          marginLeft: "15px",
          marginRight: "15px",
          marginTop: "10px",
        }}
      />
      <List>
        {drawerItemsResponsive.map((item) => (
          <ListItem
            button
            key={item.name}
            onClick={() => history.push(item.path)}
            sx={{ margin: "8px 0", height: "45px" }} // consistent margin and height
          >
            <ListItemIcon style={{ minWidth: "35px", marginLeft: "9px" }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
        <ListItem
          button
          onClick={onClickLogout}
          sx={{ margin: "8px 0", height: "45px" }} // consistent margin and height
        >
          <ListItemIcon style={{ minWidth: "35px", marginLeft: "9px" }}>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "#A3E4D7", // consistent background color
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ color: "black" }}
          >
            Welcome {globalState.userDetails.center_name}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#A3E4D7",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;
