import React, { Component, useState } from "react";
import MUIDataTable from "mui-datatables";
import ViewActions from "../../components/AdminApi/ViewActions";
import Button from "@mui/material/Button";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteAlertDialogModal from "../../components/Components/DeleteAlert";
import DeleteIcon from "@mui/icons-material/Delete";
import "../../Styles/style.css";
import ViewVerify from "../../components/AdminApi/ViewVerify";
import NewComponent from "../../components/AdminApi/ViewVerify";
import AdminApiModule from "../../components/AdminApi/Api/api";
import {
  Alert,
  Box,
  InputAdornment,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import BasicDateRangePicker from "../../components/common/datepicker";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { id } from "date-fns/locale";
import StarIcon from "@mui/icons-material/Star";
import { BASE_URL } from "../../components/config/config";
import axios from "axios";
import TechnicianDoc from "../../components/AdminApi/TechnicianDocView";
const options = {
  filterType: "dropdown",
};
const NewwComponent = () => (
  <div>
    <h1>Hello, World!</h1>
    <p>This is the new component.</p>
  </div>
);

class Technicians extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {
          name: "name",
          label: "Name",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "pendingTickets",
          label: "Pending Requests",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "closedTickets",
          label: "Closed Requests",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "TotalTickets",
          label: "Total Requests",
          options: {
            filter: true,
            sort: true,
            customFilterListOptions: {
              render: (v) => {
                if (v === "0") {
                  return "0";
                }
                if (v === "1") {
                  return "1";
                }
                if (v === "2") {
                  return "2";
                }
                if (v === "3") {
                  return "3";
                }

                if (v === "4") {
                  return "4";
                }
                if (v === "5") {
                  return "5";
                }
                if (v === "6") {
                  return "6";
                }

                if (v === "7") {
                  return "7";
                }
                if (v === "8") {
                  return "8";
                }
                if (v === "9") {
                  return "9";
                }
                if (v === "10") {
                  return "10";
                }
                if (v === "GreaterThan10") {
                  return "Greater Than 10";
                }
                return true;
              },
            },
            filterOptions: {
              names: [
                "0",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "GreaterThan10",
              ], // Corrected typo here
              logic: (value, filters) => {
                const numericValue = parseFloat(value);

                if (filters.includes("0") && numericValue != 0) {
                  return true;
                }
                if (filters.includes("1") && numericValue != 1) {
                  return true;
                }
                if (filters.includes("2") && numericValue != 2) {
                  return true;
                }
                if (filters.includes("3") && numericValue != 3) {
                  return true;
                }

                if (filters.includes("4") && numericValue != 4) {
                  return true;
                }
                if (filters.includes("5") && numericValue != 5) {
                  return true;
                }
                if (filters.includes("6") && numericValue != 6) {
                  return true;
                }
                if (filters.includes("7") && numericValue != 7) {
                  return true;
                }
                if (filters.includes("8") && numericValue != 8) {
                  return true;
                }
                if (filters.includes("9") && numericValue != 9) {
                  return true;
                }
                if (filters.includes("10") && numericValue != 10) {
                  return true;
                }

                if (filters.includes("GreaterThan10") && numericValue < 10) {
                  return true;
                }
                return false;
              },
            },
          },
        },
        {
          name: "pincode",
          label: "Service Pincode",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "email",
          label: "Email",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "phone",
          label: "Phone",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "Rating",
          label: "Rating",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {value} <StarIcon style={{ color: "yellow" }} />
                </div>
              );
            },
            filter: true,
            sort: false,
          },
        },
        {
          name: "profile_created_by",
          label: "Designation",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "referenceDetails",
          label: "Reference Details",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "address_details_permanent",
          label: "Address Details Permanent",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "associatedWith",
          label: "Associated CenterCode",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "authorizedFor",
          label: "Authorized For Clients",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "action",
          label: "View Verification Details",
          options: {
            customBodyRender: (value) => {
              console.log("value...", value);

              return (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto auto auto",
                    gridGap: "-10px !important",
                  }}
                >
                  {value &&
                    value.document_details &&
                    value.document_details.aadhar_card_document &&
                    value.document_details.aadhar_card_document.front_side &&
                    value.document_details.aadhar_card_document.front_side.endsWith(
                      ".jpg"
                    ) && (
                      <Button
                        className="text-transform-none"
                        variant="contained"
                        onClick={() => this.handleClick(value._id)}
                      >
                        Views
                      </Button>
                    )}
                </div>
              );
            },
          },
        },
        {
          name: "action",
          label: "Action",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              // console.log("cc",value);
              return (
                <>
                  {
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      width="210px"
                    >
                      <Button
                        className="text-transform-none"
                        variant="contained"
                        onClick={() => this.toggleAuthorizedForPopup(value._id)}
                      >
                        Authorized For
                      </Button>

                      <Tooltip title="Delete">
                        <Button
                          style={{ background: "#FCE9F1" }}
                          onClick={() => this.clickDeleteTicket(value?._id)}
                        >
                          <DeleteIcon style={{ color: "red" }} />
                        </Button>
                      </Tooltip>
                    </Box>
                  }
                </>
              );
            },
          },
        },
      ],
      technicianList: [],
      isAuthorizedForPopupOpen: false,
      isVerificationPopupOpen: false,
      clientsList: [],
      selectedTechnicianId: "",
      imageUrl: [],
      counter: 0,
      isDeleteAlertPopupOpen: false, // delete alert
      deleteTechnicianId: "",
      deleteHeading: "Technician",
      toast: {
        open: false,
        msg: "",
        duration: 5000,
        type: "",
      },
    };
  }

  handleClick = (image) => {
    this.props.history.push("tecnicianDocument?technicianId=" + image);
  };
  getURL = (urrl) => {
    this.setState({
      //pathname: "/tecnicianDocument",
      urrl: this.state.imgUrls,
    });
  };

  refreshData = () => {
    new AdminApiModule()
      .getAllTechnician()
      .then((res) => {
        let mappedTechnician = res?.data?.map((ite) => {
          return {
            name: ite?.personal_details?.name,
            phone:
              ite?.personal_details?.phone?.country_code +
              ite?.personal_details?.phone?.mobile_number,
            profile_created_by: ite?.profile_created_by,
            referenceDetails: ite?.referenceDetails?.reference_person_name,
            address_details_permanent:
              ite?.address_details_permanent?.address_line +
              "  " +
              ite?.address_details_permanent?.city +
              " " +
              ite?.address_details_permanent?.country,
            associatedWith: this.getAssociatedCenters(ite?.center_id),
            authorizedFor: this.getFormattedClients(ite?.clients_ids_list),
            action: ite,
            pincode: ite?.service_area_main_pincode,
          };
        });

        this.setState({ technicianList: mappedTechnician });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onClickFilter = (value) => {
    let payload = {
      start_date: value[0],
      end_date: value[1],
    };

    new AdminApiModule()
      .getAllTechnician(payload)
      .then((res) => {
        let mappedTechnician = res?.data?.map((ite) => {
          return {
            name: ite?.personal_details?.name,
            // email: ite?.personal_details?.email,
            phone:
              ite?.personal_details?.phone?.country_code +
              ite?.personal_details?.phone?.mobile_number,
            profile_created_by: ite?.profile_created_by,
            referenceDetails: ite?.referenceDetails?.reference_person_name,
            address_details_permanent:
              ite?.address_details_permanent?.address_line +
              "  " +
              ite?.address_details_permanent?.city +
              " " +
              ite?.address_details_permanent?.country,
            associatedWith: this.getAssociatedCenters(ite?.center_id),
            authorizedFor: this.getFormattedClients(ite?.clients_ids_list),
            pincode: ite?.service_area_main_pincode,
            action: ite,
            // imgUrl: ite,
          };
        });

        this.setState({ technicianList: mappedTechnician });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  componentDidMount() {
    this.getAllTechnicians();
    this.getAllClients();
  }

  showDialog = (open, type, msg) => {
    this.setState({
      toast: {
        open: open,
        msg: msg,
        duration: 5000,
        type: type,
      },
    });
  };

  closeDialog = (open = false) => {
    this.setState({
      toast: {
        open: open,
        msg: "",
        duration: 5000,
        type: "",
      },
    });
  };

  getAllClients() {
    new AdminApiModule()
      .getAllClients()
      .then((res) => {
        let parsedData = [];
        if (res && res.data && res.data.length > 0) {
          parsedData = res.data;
        }

        this.setState({
          clientsList: parsedData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getFormattedClients = (clients) => {
    let clients_list = [];
    clients &&
      clients.length > 0 &&
      clients.map((item) => {
        clients_list.push(item.client_name);
      });
    return clients_list.length > 0 ? clients_list.join(", ") : "";
  };

  getAllTechnicians() {
    new AdminApiModule()
      .getAllTechnician()
      .then((res) => {
        let mappedTechnician = res?.data?.map((ite) => {
          const pendingTickets = ite?.count_details?.pending_ticket_count || 0;
          const closedTickets = ite?.count_details?.closed_ticket_count || 0;
          const totalTickets = pendingTickets + closedTickets;
          console.log(ite);
          return {
            //technician_id: ite?.technician_id || '-',
            // techId:ite?._id,
            name: ite?.personal_details?.name,
            email: `${ite?.personal_details?.email?.slice(0, 10)}...`,

            phone:
              ite?.personal_details?.phone?.country_code +
              ite?.personal_details?.phone?.mobile_number,
            Rating: ite?.count_details?.technician_rating || 0,
            profile_created_by:
              ite?.profile_created_by === "SELF_BY_APP"
                ? "CENTER"
                : ite?.profile_created_by === "CENTER"
                ? "TECHNICIAN"
                : ite?.profile_created_by,
            referenceDetails: ite?.referenceDetails?.reference_person_name,
            // ite?.address_details_permanent?.city + " " + ite?.address_details_permanent?.country
            address_details_permanent:
              ite?.address_details_permanent?.address_line?.slice(0, 8),
            associatedWith: this.getAssociatedCenters(ite?.center_id),
            authorizedFor: this.getFormattedClients(ite?.clients_ids_list),
            personal: ite?.personal_details?.profile_picture,
            Aadharback: ite?.document_details?.aadhar_card_document?.back_side,
            Aadharfront:
              ite?.document_details?.aadhar_card_document?.front_side,
            action: ite,
            pendingTickets: pendingTickets,
            closedTickets: closedTickets,
            TotalTickets: totalTickets,
            imgUrl: ite,
            pincode: ite?.service_area_main_pincode,
          };
        });

        this.setState({ technicianList: mappedTechnician });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  toggleAuthorizedForPopup = (technicianId) => {
    if (technicianId) {
      this.setState({ selectedTechnicianId: technicianId });
    }
    this.setState((prevState) => ({
      isAuthorizedForPopupOpen: !prevState.isAuthorizedForPopupOpen,
    }));
  };
  toggleVerificationDetailsPopup = (technicianId) => {
    if (technicianId) {
      this.setState({ selectedTechnicianId: technicianId });
    }
    this.setState((prevState) => ({
      isVerificationPopupOpen: !prevState.isVerificationPopupOpen,
    }));
  };

  saveAuthorizedTechnicians = (techniciansSelected) => {
    console.log("techniciansSelected is", techniciansSelected);

    let payload = {
      technician_obj_id: this.state.selectedTechnicianId,
      clients_ids_list: [...techniciansSelected],
    };

    console.log("payload is", payload);
    new AdminApiModule()
      .adminUpdateTechnician(payload)
      .then((res) => {
        this.showDialog(true, "success", "Technician updated successfully");

        setTimeout(() => {
          this.toggleAuthorizedForPopup();
          this.getAllTechnicians();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        this.showDialog(true, "error", err?.response?.data?.message);
      });
  };
  // Delete

  clickDeleteTicket = (ticketId) => {
    this.setState({ deleteTechnicianId: ticketId });
    this.handleOpenCloseTicketDeleteModal(ticketId);
    localStorage.setItem("technicianId", ticketId);
  };

  handleOpenCloseTicketDeleteModal = () => {
    this.setState({
      isDeleteAlertPopupOpen: true,
    });
  };

  handleDeleteCloseModal = () => {
    this.setState({
      isDeleteAlertPopupOpen: false,
    });
  };

  handleDeleteRows = async () => {
    let accessToken = localStorage.getItem("access_token");
    let technician_Id = localStorage.getItem("technicianId");

    try {
      await axios.delete(`${BASE_URL}admin/deleteTechnician/${technician_Id}`, {
        headers: {
          "x-access-token": accessToken,
        },
      });
      this.handleDeleteCloseModal();
      // this.getAllTickets();
    } catch (error) {
      console.log(error);
    }
  };

  getAssociatedCenters = (centersList) => {
    let centers_list = [];
    centersList &&
      centersList.length > 0 &&
      centersList.map((item) => {
        centers_list.push(item?.qr_details?.qr_id);
      });
    return centers_list.length > 0 ? centers_list.join(", ") : "";
  };
  //let cc=0;

  render() {
    const { toast: snackBarValues } = this.state;
    return (
      <div>
        {snackBarValues.open && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackBarValues.open}
            autoHideDuration={snackBarValues.duration}
            onClose={this.closeDialog}
          >
            <Alert
              onClose={this.closeDialog}
              severity={snackBarValues.type}
              sx={{ width: "100%" }}
            >
              {snackBarValues.msg}
            </Alert>
          </Snackbar>
        )}

        {this.props.reduxState?.adminDetails?.role_id?.permissions
          ?.view_technician_details?.full_access ? (
          <>
            <BasicDateRangePicker
              onClickFilter={this.onClickFilter}
              refreshData={this.refreshData}
            />
            <MUIDataTable
              title={"Technicians"}
              data={this.state.technicianList}
              columns={this.state.columns}
              options={options}
            />
          </>
        ) : (
          "No permission , contact admin"
        )}

        {this.state.isDeleteAlertPopupOpen && (
          <DeleteAlertDialogModal
            openAlert={this.state.isDeleteAlertPopupOpen}
            handleAlertClose={this.handleDeleteCloseModal}
            handleDeleteTicket={this.handleDeleteRows}
            Heading={this.state.deleteHeading}
          />
        )}

        <ViewActions
          isAuthorizedForPopupOpen={this.state.isAuthorizedForPopupOpen}
          clientsList={this.state.clientsList}
          toggleAuthorizedForPopup={this.toggleAuthorizedForPopup}
          saveAuthorizedCenters={this.saveAuthorizedTechnicians}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reduxState: state.userReducer,
});

export default connect(mapStateToProps, null)(withRouter(Technicians));