import { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Trips from "./pages/trips";
import PublicTicketBooking from "./pages/PublicTicketBooking";
import PublicTicketBookingQr from "./pages/publicTicketBookingQr";
import CenterRegistration from "./pages/CenterRegistration";
import Technicians from "./pages/Technicians";
import Tickets from "./pages/Admin/Tickets";
import Login from "./pages/login";
import Centers from "./pages/Admin/Centers";
import Home from "./components/NavBar/Home";
import Reopen from "./pages/Admin/Reopen";
import AddTechnician from "./pages/AddTechnician";
import AddTicket from "./pages/AddTicket";
import AdminTechnician from "./pages/Admin/technician";
import AdminLogin from "./pages/Admin/adminLogin";
import PrivateRoute from "./helper/privateRoute";
import PublicRoute from "./helper/publicRoute";
import AdminPrivateRoute from "./helper/privateRouteAdmin";
import AdminPublicRoute from "./helper/adminPublicRoute";
import CenterOnboardRoute from "./helper/privateRouteCenterOnboarding";
import AddClientTicket from "./pages/Admin/AddClientTicket";
import TicketsCenter from "./pages/ticketCenter";
import CenterDashboard from "./pages/CenterDashboard";
import BroadCastedRequests from "./pages/broadcastedRequests";
import TicketCreatedByYou from "./pages/ticketCreatedByYou";
import CenterTicket from "./pages/Admin/CenterTicket";
import NavBar from "./components/NavBar/navbar";
import AdminNavBar from "./components/NavBar/adminPannel";
import CenterOnboardNavBar from "./components/NavBar/CenterOnboardNavbar";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import BroadcastTickets from "./pages/Admin/BroadcastTickets";
import AdminClients from "./pages/Admin/AdminClients";
import AdminRoles from "./pages/Admin/Roles/AdminRoles";
import CloseTicket from "./pages/Admin/CloseTicket";
import NoSerTickets from "./pages/Admin/NoSerTickets";
import B2btickets from "./pages/Admin/b2btickets";
import Downloadformat from "./pages/Admin/downloadformat";
import Reportpage from "./pages/Admin/reportpage";
import PendingTickets from "./pages/Admin/PendingTickets";
import AddTicketAdmin from "./pages/Admin/AddNewTicket";
import AdminBroadCastList from "./pages/Admin/broadcastedList";
import AdminAddCenter from "./pages/Admin/AddCenter";
import Dashboard from "./pages/Admin/Dashboard";
import Wallet_client from "./pages/Admin/Wallet_client";
import PaymentsList from "./pages/payments";
import ViewTicketPage from "./pages/Admin/veiwTicket";
import ForgotPassword from "./pages/Admin/ForgotPassword";
import FeedBackComponent from "./pages/feedBack";
import AdminFeedBack from "./pages/Admin/FeedBack";
import AddRole from "./pages/Admin/Roles/AddRole";
import AdminUsers from "./pages/Admin/Users/AdminUsers";
import AddUser from "./pages/Admin/Users/AddUser";
import AdminCenterOnboarders from "./pages/Admin/AdminCenterOnboarders/AdminCenterOnboarders";
import AddCenterOnboarder from "./pages/Admin/AdminCenterOnboarders/AddCenterOnboarder";
import Notifications from "./components/Components/Notifications";
import CenterOnboardRegistration from "./pages/CenterOnboarding/CenterOnboardRegistration";
import CenterOnboardLogin from "./pages/CenterOnboarding/CenterOnboardLogin";
import CenterOnboardAddCenter from "./pages/CenterOnboarding/components/CenterOnboardAddCenter";
import CenterOnboardViewCenters from "./pages/CenterOnboarding/components/CentersList";
import ServiceStatus from "./components/ServicesStatus/ServiceStatus";
import EditCenter from "./pages/Admin/EditCenter";
import WalletHistory from "./pages/Admin/PaymentDetails/WalletHistory";
import PaymentHistory from "./pages/Admin/PaymentDetails/PaymentTransacion";
import UserDetailsTable from "./pages/Admin/Users/UserTable";
import TechnicianDocView from "./components/AdminApi/TechnicianDocView";
import TicketDocView from "./components/AdminApi/TicketDocView";
import CenterHistory from "./pages/Admin/CenterHistory";
import PublicPickDropQr from "./pages/PublicPickDropQr";
import ERickshawTicket from "./pages/Admin/ERickshawTicket";
import NotificationLog from "./components/Components/NotificationLog";
import AllClientTickets from "./pages/Admin/AllClientTickets";
import Banner from "./pages/Admin/Banner";
import Offers from "./pages/Admin/Offers";
import Subscription from "./pages/Admin/Subscription";

const stylesSheet = {
  styleForDiv: {
    marginTop: "8%",
    marginLeft: "20%",
    marginRight: "5%",
  },
};

const Routes = () => (
  <Suspense>
    <Switch>
      {/* <PublicRoute exact path="/" component={Login} /> */}
      <PublicRoute exact path="/">
        <Redirect to="/booking" />
      </PublicRoute>

      {/* <PublicRoute exact path="/">
        <Redirect to="/booking/:id" />
      </PublicRoute> */}

      {/* Code changes */}
      <Route
        exact
        path="/serviceStatus"
        component={ServiceStatus}
        restricted={false}
      />
      {/* <PublicRoute
        exact
        path="/serviceStatus/:id"
        component={ServiceStatus}
        restricted={false}
      /> */}

      {/* <PrivateRoute path="/trips" component={Trips} /> */}
      <Route
        path="/registration/:centerOnboarderId?"
        component={CenterRegistration}
      />

      {/* Feedback page component Route */}
      <Route path="/feedback/:id" component={FeedBackComponent} />
      <Route path="/booking/:id" exact component={PublicTicketBookingQr} />
      <Route path="/bookingPickDrop/:id" exact component={PublicPickDropQr} />

      <Route path="/booking" exact component={PublicTicketBooking} />
      {/* <Route path="/NewComponent" exact component={NewComponent} /> */}

      <PublicRoute path="/login" component={Login} />
      <PublicRoute path="/forgot-password" component={ForgotPassword} />

      {/* Center Pages Routes Only */}
      <PrivateRoute
        path="/center-dashboard"
        component={() => {
          return (
            <>
              <NavBar />
              <div style={stylesSheet.styleForDiv}>
                <CenterDashboard />
              </div>
            </>
          );
        }}
      />

      <PrivateRoute
        path="/Technicians"
        component={() => {
          return (
            <>
              <NavBar />
              <div style={stylesSheet.styleForDiv}>
                <Technicians />
              </div>
            </>
          );
        }}
      />
      <AdminPrivateRoute
        path="/banner"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <Banner />
              </div>
            </>
          );
        }}
      />
      <AdminPrivateRoute
        path="/offers"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <Offers />
              </div>
            </>
          );
        }}
      />
      <AdminPrivateRoute
        path="/subscription"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
              {/* backgroundColor: "#def6f1", */}
                <Subscription />
              </div>
            </>
          );
        }}
      />
      <AdminPrivateRoute
        path="/tecnicianDocument"
        component={() => {
          return (
            <>
              {/* <NavBar />
          <div style={stylesSheet.styleForDiv}> */}

              <TechnicianDocView />

              {/* </> </div> */}
            </>
          );
        }}
      />

      <AdminPrivateRoute
        path="/ticketDocument"
        component={() => {
          return (
            <>
              {/* <NavBar />
          <div style={stylesSheet.styleForDiv}> */}

              <TicketDocView />

              {/* </> </div> */}
            </>
          );
        }}
      />

      <PrivateRoute
        path="/brodcast-requests"
        component={() => {
          return (
            <>
              <NavBar />
              <div style={stylesSheet.styleForDiv}>
                <BroadCastedRequests />
              </div>
            </>
          );
        }}
      />

      <PrivateRoute
        path="/payments"
        component={() => {
          return (
            <>
              <NavBar />
              <div style={stylesSheet.styleForDiv}>
                <PaymentsList />
              </div>
            </>
          );
        }}
      />

      <AdminPrivateRoute
        path="/Home"
        component={() => (
          <>
            {/* <NavBar /> */}
            {/* <div style={stylesSheet.styleForDiv}> */}
            <Home />
            {/* </div> */}
          </>
        )}
      />

      <PrivateRoute
        path="/AddTechnician"
        component={() => {
          return (
            <>
              <NavBar />
              <div style={stylesSheet.styleForDiv}>
                <AddTechnician />
              </div>
            </>
          );
        }}
      />

      <PrivateRoute
        path="/AddTicket"
        component={() => {
          return (
            <>
              <NavBar />
              <div style={stylesSheet.styleForDiv}>
                <AddTicket />
              </div>
            </>
          );
        }}
      />

      <PrivateRoute
        path="/ticket-created-by-you"
        component={() => {
          return (
            <>
              <NavBar />
              <div style={stylesSheet.styleForDiv}>
                <TicketCreatedByYou />
              </div>
            </>
          );
        }}
      />

      <PrivateRoute
        component={() => {
          return (
            <>
              <NavBar />
              <div style={stylesSheet.styleForDiv}>
                <TicketsCenter />
              </div>
            </>
          );
        }}
        path="/tickets-center"
      />

      <AdminPublicRoute path="/admin-login" component={AdminLogin} />

      {/* Admin Pages Routes Only */}
      <AdminPrivateRoute
        path="/admin-dashboard"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <AdminDashboard />
              </div>
            </>
          );
        }}
      />

      <AdminPrivateRoute
        path="/client-dashboard"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <Dashboard />
              </div>
            </>
          );
        }}
      />
      {/* <AdminPrivateRoute
        path="/center-history"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <CenterHistory />
              </div>
            </>
          );
        }}
      /> */}

      <AdminPrivateRoute
        path="/center-history/:centerId"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <CenterHistory />
              </div>
            </>
          );
        }}
      />
      <AdminPrivateRoute
        path="/reopen-log/:id"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <Reopen />
              </div>
            </>
          );
        }}
      />

      <AdminPrivateRoute
        path="/wallet-client"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <Wallet_client />
              </div>
            </>
          );
        }}
      />

      <AdminPrivateRoute
        path="/broad-casted-list"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <AdminBroadCastList />
              </div>
            </>
          );
        }}
      />
      <AdminPrivateRoute
        path="/add-center"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <AdminAddCenter />
              </div>
            </>
          );
        }}
      />

      <AdminPrivateRoute
        path="/view-ticket"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <ViewTicketPage />
              </div>
            </>
          );
        }}
      />

      {/* User table */}

      <AdminPrivateRoute
        path="/usersdetails"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <UserDetailsTable />
              </div>
            </>
          );
        }}
      />

      {/* Payment tabs */}
      <AdminPrivateRoute
        path="/wallet"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <WalletHistory />
              </div>
            </>
          );
        }}
      />

      <AdminPrivateRoute
        path="/payment"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <PaymentHistory />
              </div>
            </>
          );
        }}
      />

      <AdminPrivateRoute
        path="/edit-center"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <EditCenter />
              </div>
            </>
          );
        }}
      />

      <AdminPrivateRoute
        path="/admin-centers"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <Centers />
              </div>
            </>
          );
        }}
      />

      <AdminPrivateRoute
        path="/admin-ticket"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <AddTicketAdmin />
              </div>
            </>
          );
        }}
      />

      <AdminPrivateRoute
        path="/admin-feedBack"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <AdminFeedBack />
              </div>
            </>
          );
        }}
      />

      <AdminPrivateRoute
        path="/Tickets"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <Tickets />
              </div>
            </>
          );
        }}
      />

      <AdminPrivateRoute
        path="/pending-ticket"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <PendingTickets />
              </div>
            </>
          );
        }}
      />
      <AdminPrivateRoute
        path="/erickshaw-ticket"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <ERickshawTicket />
              </div>
            </>
          );
        }}
      />
      <AdminPrivateRoute
        path="/notification-history"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <NotificationLog />
              </div>
            </>
          );
        }}
      />
      <AdminPrivateRoute
        path="/close-ticket"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <CloseTicket />
              </div>
            </>
          );
        }}
      />
      <AdminPrivateRoute
        path="/noService-ticket"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <NoSerTickets />
              </div>
            </>
          );
        }}
      />
      <AdminPrivateRoute
        path="/center-ticket"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <CenterTicket />
              </div>
            </>
          );
        }}
      />
      <AdminPrivateRoute
        path="/b2b-ticket"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <B2btickets />
              </div>
            </>
          );
        }}
      />
      <AdminPrivateRoute
        path="/all-client-ticket"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <AllClientTickets />
              </div>
            </>
          );
        }}
      />
      <AdminPrivateRoute
        path="/add-client-ticket"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <AddClientTicket />
              </div>
            </>
          );
        }}
      />
      <AdminPrivateRoute
        path="/download-format"
        component={() => {
          return (
            <>
              <div style={stylesSheet.styleForDiv}>
                <Downloadformat />
              </div>
            </>
          );
        }}
      />
      <AdminPrivateRoute
        path="/report-page"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <Reportpage />
              </div>
            </>
          );
        }}
      />

      <AdminPrivateRoute
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <AdminTechnician />
              </div>
            </>
          );
        }}
        path="/admin-technician"
      />

      <AdminPrivateRoute
        path="/admin-broadcast-tickets"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <BroadcastTickets />
              </div>
            </>
          );
        }}
      />

      <AdminPrivateRoute
        path="/admin-clients"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <AdminClients />
              </div>
            </>
          );
        }}
      />

      <AdminPrivateRoute
        path="/roles"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <AdminRoles />
              </div>
            </>
          );
        }}
      />

      <AdminPrivateRoute
        path="/add-role"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <AddRole />
              </div>
            </>
          );
        }}
      />

      <AdminPrivateRoute
        path="/users"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <AdminUsers />
              </div>
            </>
          );
        }}
      />

      <AdminPrivateRoute
        path="/notifications"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <Notifications />
              </div>
            </>
          );
        }}
      />

      <AdminPrivateRoute
        path="/add-user"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <AddUser />
              </div>
            </>
          );
        }}
      />

      <AdminPrivateRoute
        path="/admin-center-onboarders"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <AdminCenterOnboarders />
              </div>
            </>
          );
        }}
      />

      <AdminPrivateRoute
        path="/admin-add-center-onboarder"
        component={() => {
          return (
            <>
              <AdminNavBar />
              <div style={stylesSheet.styleForDiv}>
                <AddCenterOnboarder />
              </div>
            </>
          );
        }}
      />

      <Route
        path="/center-onboard-registration"
        component={CenterOnboardRegistration}
      />
      <Route path="/center-onboard-login" component={CenterOnboardLogin} />

      <CenterOnboardRoute
        path="/center-onboard-add-center"
        component={() => {
          return (
            <>
              <CenterOnboardNavBar />
              <div style={stylesSheet.styleForDiv}>
                <CenterOnboardAddCenter />
              </div>
            </>
          );
        }}
      />
      <CenterOnboardRoute
        path="/center-onboard-view-centers"
        component={() => {
          return (
            <>
              <CenterOnboardNavBar />
              <div style={stylesSheet.styleForDiv}>
                <CenterOnboardViewCenters />
              </div>
            </>
          );
        }}
      />
    </Switch>
  </Suspense>
);

export default Routes;
