import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { BASE_URL } from "../config/config";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  Alert,
  CircularProgress,
  InputAdornment,
  Snackbar,
} from "@mui/material";
import FileUploader from "./OnsiteImageUploader";
import AdminApiModule from "../AdminApi/Api/api";
import RevisitFileUploader from "./RevisitPicture";
import { Backdrop } from "@mui/material";

const style = {
  flex: 1,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "600px",
  overflowY: "auto",
};

const stylesCss = {
  inputBox: {
    width: "100%",
  },
  dialogInputBox: {
    height: 70,
    padding: 10,
    width: 300,
  },
  gridStyle: {},
  paddingInnerGrids: {
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingTop: "10px",
    margin: 10,
    justifyContent: "center",
  },
  dialogAlignBox: {},
};

let feedBackOptionList = [
  "Visit Done, Work Completed",
  "Visited - Estimate Not Approved / Site Not Ready",
  "Visited - Unit not Repairable /Special Skill, Tools, Authorisation required",
  "Not Visited -Can’t Deliver Service - Phone not Pick/ Far Location",
  "Not Visited - Customer Refused (Work Already Done /Not Needed / Site Not Ready)",
];

export default function AssignTechnician({
  open,
  handleClose,
  handleCloseTicket,
  getSingleTicketData,
  handleRevisitCloseTicket,
  // Center Ticket Close Props
  getSingleCenterTicketData,
  handleCenterRevisitCloseTicket,
}) {
  const [closingTicketRemarks, setClosingTicketRemarks] = useState("");
  const [closingPrice, setClosingPrice] = useState(null);
  const [closingRemark, setClosingRemark] = useState(null);
  const [closingCode, setClosingCode] = useState("");
  const [revisitRemark, setRevisitVisit] = useState("");
  const [isUploadComplete, setIsUploadComplete] = useState(false);

  const checkClosingTicketType = getSingleTicketData?.[0]?.revisit_status;
  // const happyCOde = getSingleTicketData?.[0]?.code?.happy_code
  // console.log(
  //   happyCOde,
  //   "<------This getSingleTicketData is the data😊😊😊😊😊😊😊😊😊😊😊😊"
  // );
  const checkCenterClosingTicketType =
    getSingleCenterTicketData?.[0]?.revisit_status;
  console.log(
    checkCenterClosingTicketType,
    "<------This is the Center Ticket data😊😊😊😊😊😊😊😊😊😊😊😊"
  );

  // Get One Ticket data

  const handleUploadComplete = () => {
    setIsUploadComplete(true);
  };
  const [snackBarValues, setSnackBarValues] = React.useState({
    open: false,
    msg: "",
    duration: 5000,
    type: "",
  });

  const checkImg = localStorage.getItem("checkImg");
  console.log("checkImg", checkImg);
  // Image upload state
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImage, setIsImage] = useState(false);

  const showDialog = (open, type, msg) => {
    setSnackBarValues({
      open: open,
      msg: msg,
      duration: 5000,
      type: type,
    });
  };

  const closeDialog = (open = false) => {
    setSnackBarValues({
      open: open,
      msg: "",
      duration: 5000,
      type: "",
    });
  };

  const handleChange = (e) => {
    console.log(e.target.value, "closingTicketRemarks");
    setClosingTicketRemarks(e.target.value);
  };
  console.log("closingCode", closingCode);

  // Image upload funtion
  const handleImageUpload = async (event) => {
    event.preventDefault();
    if (selectedImage) {
      const formData = new FormData();
      //   technicianProfilePictures which name accept in backend side
      formData.append("technicianProfilePictures", selectedImage);

      try {
        const response = await fetch(
          `${BASE_URL}/technicianApp/upload-technician_profile_picture/6461b9d358c16554fae98d39`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          // Image uploaded successfully
          setIsImage(true);
          console.log("Image uploaded!");
        } else {
          // Handle upload error
          console.error("Image upload failed.");
        }
      } catch (error) {
        console.error("Error occurred while uploading the image:", error);
      }
    }
  };

  // Image upload onChange
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  console.log("isImage", isImage);
  return (
    <div>
      {snackBarValues.open && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={snackBarValues.open}
          autoHideDuration={snackBarValues.duration}
          onClose={closeDialog}
        >
          <Alert
            onClose={closeDialog}
            severity={snackBarValues.type}
            sx={{ width: "100%" }}
          >
            {snackBarValues.msg}
          </Alert>
        </Snackbar>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={ !checkCenterClosingTicketType}
  // onClick={handleClose}
>
  <CircularProgress color="inherit" />
</Backdrop> */}

          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Closing Remarks
          </Typography>

          {/* Onsite Image component  */}
          {checkClosingTicketType || checkCenterClosingTicketType == true ? (
            // if ticket is  closed and we are  rendering extra form conditionally
            <>
              <Grid style={stylesCss.paddingInnerGrids} item>
                <InputLabel id="demo-simple-select-helper-label">
                  Choose Closing Remarks
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Closing Remarks"
                  placeholder="Please select"
                  name="closingTicketRemarks"
                  style={stylesCss.inputBox}
                  onChange={(event) => handleChange(event)}
                  value={closingTicketRemarks}
                >
                  {feedBackOptionList?.map((ite) => {
                    return (
                      <MenuItem
                        value={ite}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        {ite}
                      </MenuItem>
                    );
                  })}
                </Select>
                {console.log("closingTicketRemarks", closingTicketRemarks)}
                {/* <TextField
                            id="demo-helper-text-misaligned"
                            label="Closing Remarks"
                            style={stylesCss.inputBox}
                            name="closingTicketRemarks"
                            value={closingTicketRemarks}
                            onChange={(event) => handleChange(event)}
                        /> */}
              </Grid>
              <Grid style={stylesCss.paddingInnerGrids} item>
                <TextField
                  id="demo-helper-text-misaligned"
                  label="Review Remark"
                  style={stylesCss.inputBox}
                  name="closingTicketRemarks"
                  type="text"
                  value={closingRemark}
                  onChange={(event) => setClosingRemark(event.target.value)}
                />
              </Grid>

              <Grid style={stylesCss.paddingInnerGrids} item>
                <TextField
                  id="demo-helper-text-misaligned"
                  label="Revisit Remark"
                  style={stylesCss.inputBox}
                  name="revisitRemark"
                  type="text"
                  value={revisitRemark}
                  onChange={(event) => setRevisitVisit(event.target.value)}
                />
              </Grid>

              <Grid style={stylesCss.paddingInnerGrids} item>
                <TextField
                  id="demo-helper-text-misaligned"
                  label="Code"
                  style={stylesCss.inputBox}
                  name="code"
                  type="text"
                  value={closingCode}
                  inputProps={{ maxLength: 4, pattern: "^[0-9]*$" }}
                  onChange={(event) => setClosingCode(event.target.value)}
                />
              </Grid>

              <Grid style={stylesCss.paddingInnerGrids} item>
                <TextField
                  id="demo-helper-text-misaligned"
                  label="Price"
                  style={stylesCss.inputBox}
                  name="price"
                  type="number"
                  value={closingPrice}
                  onChange={(event) => setClosingPrice(event.target.value)}
                />
              </Grid>
              <RevisitFileUploader />
            </>
          ) : (
            <>
              <Grid style={stylesCss.paddingInnerGrids} item>
                <InputLabel id="demo-simple-select-helper-label">
                  Choose Closing Remarks
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Closing Remarks"
                  placeholder="Please select"
                  name="closingTicketRemarks"
                  style={stylesCss.inputBox}
                  onChange={(event) => handleChange(event)}
                  value={closingTicketRemarks}
                >
                  {feedBackOptionList?.map((ite) => {
                    return (
                      <MenuItem
                        value={ite}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        {ite}
                      </MenuItem>
                    );
                  })}
                </Select>
                {console.log("closingTicketRemarks", closingTicketRemarks)}
                {/* <TextField
                            id="demo-helper-text-misaligned"
                            label="Closing Remarks"
                            style={stylesCss.inputBox}
                            name="closingTicketRemarks"
                            value={closingTicketRemarks}
                            onChange={(event) => handleChange(event)}
                        /> */}
              </Grid>
              <Grid style={stylesCss.paddingInnerGrids} item>
                <TextField
                  id="demo-helper-text-misaligned"
                  label="Review Remark"
                  style={stylesCss.inputBox}
                  name="closingTicketRemarks"
                  type="text"
                  value={closingRemark}
                  onChange={(event) => setClosingRemark(event.target.value)}
                />
              </Grid>

              <Grid style={stylesCss.paddingInnerGrids} item>
                <TextField
                  id="demo-helper-text-misaligned"
                  label="Code"
                  style={stylesCss.inputBox}
                  name="code"
                  type="text"
                  value={closingCode}
                  inputProps={{ maxLength: 4, pattern: "^[0-9]*$" }}
                  onChange={(event) => setClosingCode(event.target.value)}
                />
              </Grid>

              <Grid style={stylesCss.paddingInnerGrids} item>
                <TextField
                  id="demo-helper-text-misaligned"
                  label="Price"
                  style={stylesCss.inputBox}
                  name="price"
                  type="number"
                  value={closingPrice}
                  onChange={(event) => setClosingPrice(event.target.value)}
                />
              </Grid>
              <FileUploader onUploadComplete={handleUploadComplete} />
            </>
          )}

          <Grid style={stylesCss.paddingInnerGrids} item>
            {checkClosingTicketType == true ? (
              <Button
                variant="contained"
                onClick={() =>
                  handleRevisitCloseTicket(
                    closingRemark,
                    closingPrice,
                    closingCode,
                    closingTicketRemarks,
                    revisitRemark
                  )
                }
              >
                Close the ticket
              </Button>
            ) : checkCenterClosingTicketType ? (
              <Button
                variant="contained"
                onClick={() =>
                  handleCenterRevisitCloseTicket(
                    closingRemark,
                    closingPrice,
                    closingCode,
                    closingTicketRemarks,
                    revisitRemark
                  )
                }
                // disabled={
                //   !isUploadComplete ||
                //   !(closingRemark && closingPrice && closingCode)
                // }
              >
                Close Center Ticket{" "}
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() =>
                  handleCloseTicket(
                    closingRemark,
                    closingPrice,
                    closingCode,
                    closingTicketRemarks
                  )
                }
                disabled={
                  !isUploadComplete ||
                  !(closingRemark && closingPrice && closingCode)
                }

                // disabled={isUploadComplete  }
                // || !(closingRemark && closingPrice && closingCode )
              >
                Close the ticket
              </Button>
            )}
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
