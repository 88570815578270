import {
  Box,
  Modal,
  Table,
  Paper,
  Button,
  TableRow,
  Container,
  TableCell,
  TableBody,
  TableHead,
  Typography,
  TextField,
  TableContainer,
  TableSortLabel,
} from "@mui/material";
import dayjs from "dayjs";
import axios from "axios";
import { Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { BASE_URL } from "../../components/config/config";

const OffersPage = () => {
  const [sortKey, setSortKey] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [offers, setOffers] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    category: "CONSUMER",
    fromDate: null,
    toDate: null,
    offerId: null, // Add offerId to the formData to track when editing
  });

  const handleSort = (key) => {
    const isAsc = sortKey === key && sortOrder === "asc";
    setSortKey(key);
    setSortOrder(isAsc ? "desc" : "asc");
  };

  const clearFilters = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const openModal = (offer = null) => {
    if (offer) {
      // If editing an existing offer, populate formData with offer details
      setFormData({
        offerId: offer._id, // Set the offer ID for editing
        title: offer.title,
        description: offer.description,
        category: offer.category,
        fromDate: dayjs(offer.fromDate),
        toDate: dayjs(offer.toDate),
      });
    } else {
      // Reset formData for creating a new offer
      setFormData({
        offerId: null,
        title: "",
        description: "",
        category: "CONSUMER",
        fromDate: null,
        toDate: null,
      });
    }
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);

  const handleStatusChange = async (offerId, isActive) => {
    const url = `${BASE_URL}center/offercode/editstatus`;
    const payload = {
      offerId: offerId,
      isActive: isActive ? "true" : "false",
    };

    try {
      await axios.post(url, payload);
      // Optionally, you can trigger the fetch to update the offers list
      getAllOffers();
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleSubmit = async () => {
    const url = formData.offerId
      ? `${BASE_URL}center/update-offercode`
      : `${BASE_URL}center/add-offer`;

    const payload = {
      ...formData,
      // If editing, include the offerId for updating, otherwise just use formData
      ...(formData.offerId && { offerId: formData.offerId }),
    };

    try {
      await axios.post(url, payload);
      getAllOffers(); // Fetch updated offers after submission
      closeModal(); // Close the modal
    } catch (error) {
      console.error("Error submitting offer:", error);
    }
  };

  const filteredOffers = offers?.filter((offer) => {
    const createdAt = dayjs(offer.createdAt);
    const start = startDate ? dayjs(startDate) : null;
    const end = endDate ? dayjs(endDate) : null;
    if (start && createdAt.isBefore(start, "day")) return false;
    if (end && createdAt.isAfter(end, "day")) return false;
    return true;
  });

  const sortedOffers = [...filteredOffers]?.sort((a, b) => {
    const orderMultiplier = sortOrder === "asc" ? 1 : -1;
    if (sortKey === "toDate")
      return orderMultiplier * (new Date(a.toDate) - new Date(b.toDate));
    return orderMultiplier * (new Date(a[sortKey]) - new Date(b[sortKey]));
  });

  const getAllOffers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}center/get-offer/ADMIN`);
      setOffers(response?.data?.data);
    } catch (error) {
      console.error("Error fetching offers:", error);
    }
  };

  const handleDelete = async (offerId) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}center/offercode/delete/${offerId}`
      );
      if (response.status === 200) {
        // Successfully deleted, now refresh the offers list
        getAllOffers();
      }
    } catch (error) {
      console.error("Error deleting offer:", error);
    }
  };

  useEffect(() => {
    getAllOffers();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container>
        <Typography color={"#000"} variant="h4" sx={{ my: 2 }}>
          Offers
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={openModal}
            sx={{ position: "fixed", top: 16, right: 16 }}
          >
            Create Offer
          </Button>
        </Box>

        <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
          <Button variant="contained" onClick={clearFilters}>
            Clear Filters
          </Button>
        </div>

        {sortedOffers?.length === 0 ? (
          <Typography variant="h6">No offers available</Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>SN</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortKey === "createdAt"}
                      direction={sortKey === "createdAt" ? sortOrder : "asc"}
                      onClick={() => handleSort("createdAt")}
                    >
                      Created Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortKey === "toDate"}
                      direction={sortKey === "toDate" ? sortOrder : "asc"}
                      onClick={() => handleSort("toDate")}
                    >
                      Expiration Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedOffers.map((offer, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{offer?.title}</TableCell>
                    <TableCell>{offer?.description}</TableCell>
                    <TableCell>{offer?.category}</TableCell>
                    <TableCell>
                      {dayjs(offer?.createdAt).format("YYYY-MM-DD")}
                    </TableCell>
                    <TableCell>
                      {dayjs(offer?.toDate).format("YYYY-MM-DD")}
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={offer?.isActive} // Assuming `isActive` is a string
                        onChange={(event) =>
                          handleStatusChange(offer._id, event.target.checked)
                        }
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => openModal(offer)}>Edit</Button>
                      <Button onClick={() => handleDelete(offer._id)}>
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Modal open={modalOpen} onClose={closeModal}>
          <Box
            sx={{
              p: 3,
              backgroundColor: "white",
              margin: "auto",
              width: 400,
              mt: 10,
            }}
          >
            <Typography variant="h6">
              {formData.offerId ? "Edit Offer" : "Add Offer"}
            </Typography>
            <TextField
              label="Title"
              fullWidth
              value={formData.title}
              onChange={(e) =>
                setFormData({ ...formData, title: e.target.value })
              }
              sx={{ my: 2 }}
            />
            <TextField
              label="Description"
              fullWidth
              value={formData.description}
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
              sx={{ my: 2 }}
            />
            <TextField
              label="Category"
              fullWidth
              value={formData.category}
              onChange={(e) =>
                setFormData({ ...formData, category: e.target.value })
              }
              sx={{ my: 2 }}
            />
            <DatePicker
              label="Start Date"
              value={formData.fromDate}
              onChange={(newValue) =>
                setFormData({ ...formData, fromDate: newValue })
              }
              renderInput={(params) => <TextField {...params} />}
              sx={{ my: 2 }}
            />
            <DatePicker
              label="End Date"
              value={formData.toDate}
              onChange={(newValue) =>
                setFormData({ ...formData, toDate: newValue })
              }
              renderInput={(params) => <TextField {...params} />}
              sx={{ my: 2 }}
            />
            <Button variant="contained" fullWidth onClick={handleSubmit}>
              {formData.offerId ? "Update Offer" : "Create Offer"}
            </Button>
          </Box>
        </Modal>
      </Container>
    </LocalizationProvider>
  );
};

export default OffersPage;
