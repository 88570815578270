import React from "react";
import {
  FooterContainer,
  FooterBox,
  FooterText,
  FooterDesc,
  FooterIcon,
  FooterIconBox,
} from "./FooterElement";

import faceIcon from "./Images/Circle_Button.png";
import twiIcon from "./Images/CircleButton-1.png";
import linkIcon from "./Images/CircleButton-2.png";
import youIcon from "./Images/CircleButton-3.png";
import telIcon from "./Images/CircleButton-4.png";
import whatsIcon from "./Images/whatspp.png";

export const Footer = () => {
  return (
    <FooterContainer>
      <FooterBox style={{ justifyContent: "space-around" }}>
        {/* Wrap the "Terms of Service" text in an anchor tag */}
        <FooterText>
          <a
            href="https://www.onit.services/terms-of-use"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "white" }}
          >
            Terms of Service
          </a>
        </FooterText>
        <FooterText>
          <a
            href="https://www.onit.services/refund-policy"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "white" }}
          >
            Refund Policy
          </a>
        </FooterText>
      </FooterBox>
      <FooterBox>
        <FooterDesc>
          Copyright © 2023{" "}
          <a
            href="https://gbunny.online"
            target="_blank"
            rel="noopener noreferrer"
          >
            Growth Bunny
          </a>
        </FooterDesc>
      </FooterBox>
      <FooterBox>
        <FooterIconBox>
          <a
            href="https://www.facebook.com/profile.php?id=100083150591316"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FooterIcon src={faceIcon} />
          </a>
        </FooterIconBox>
        <FooterIconBox>
          <a
            href="https://twitter.com/services_onit?t=SquaGUwKq6gK6wi1PisNKQ&s=08"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FooterIcon src={twiIcon} />
          </a>
        </FooterIconBox>
        <FooterIconBox>
          <a
            href="https://www.linkedin.com/company/onit-services/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FooterIcon src={linkIcon} />
          </a>
        </FooterIconBox>
        <FooterIconBox>
          <a
            href="https://www.youtube.com/channel/UCuhzqj34gLM10HFT8BUXQGg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FooterIcon src={youIcon} />
          </a>
        </FooterIconBox>
        <FooterIconBox>
          <a
            href="https://wa.me/+917836072190"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FooterIcon src={whatsIcon} />
          </a>
        </FooterIconBox>
      </FooterBox>
    </FooterContainer>
  );
};
