import React, { Component } from "react";
import MUIDataTable from "mui-datatables";

import { Chip } from "@material-ui/core";
import "../../Styles/style.css";
import AdminApiModule from "../../components/AdminApi/Api/api";
import ViewActions from "../../components/AdminApi/ViewActions";
import ViewVerify from "../../components/AdminApi/ViewVerify";
import Button from "@mui/material/Button";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import StarIcon from "@mui/icons-material/Star";
import { Alert, InputAdornment, Snackbar, Typography } from "@mui/material";
import BasicDateRangePicker from "../../components/common/datepicker";
import { withRouter } from "react-router";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Radio } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { connect } from "react-redux";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import { BASE_URL } from "../../components/config/config";
import DeleteAlertDialogModal from "../../components/Components/DeleteAlert";
import InputBase from "@material-ui/core/InputBase";
import { MicNone } from "@mui/icons-material";
import moment from "moment";

const CustomSwitch = withStyles({
  switchBase: {
    color: "#303F9F",
    "&$checked": {
      color: "#303F9F",
    },
    "&$checked + $track": {
      backgroundColor: "#303F9F",
    },
  },
  checked: {},
  track: {},
})(Switch);
const CustomFilter = withStyles({})(InputBase);

const options = {
  // Other options...
  filterType: "dropdown",
  filter: true,
  download: true,
  downloadOptions: {
    filterOptions: {
      useDisplayedRowsOnly: true, // Download only the currently displayed (filtered) rows
      useDisplayedColumnsOnly: true, // Download only the currently displayed (filtered) columns
    },
  },
  customFilterMenuRender: (filterValue, onFilterUpdate, column) => {
    return (
      <CustomFilter
        value={filterValue || ""}
        onChange={(event) => onFilterUpdate(event.target.value)}
        placeholder={`Filter ${column.label}`}
      />
    );
  },
  downloadProps: {
    filterOptions: {
      useDisplayedRowsOnly: true,
      useDisplayedColumnsOnly: true,
    },
  },

  // Other options...
};

const activeStatus = {
  2: "DRAFTED",
  "-1": "IN_ACTIVE",
  1: "ACTIVE",
  0: "DELETED",
};

class Centers extends Component {
  constructor(props) {
    super(props);
    const { myData } = this.props;
    this.state = {
      centersList: [],
      snackbar: {
        open: false,
        message: "",
        severity: "success", // success or error
      },
      error: "",
      success: "",
      centersList: [],
      columns: [
        {
          name: "center_name",
          label: "Name",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "closedTickets",
          label: "Total Request closed",
          options: {
            filter: true,
            sort: true,
            customFilterListOptions: {
              render: (v) => {
                if (v === "0") {
                  return "0";
                }
                if (v === "1") {
                  return "1";
                }
                if (v === "2") {
                  return "2";
                }
                // Repeat for values 3 to 10
                if (v === "3") {
                  return "3";
                }
                if (v === "4") {
                  return "4";
                }
                if (v === "5") {
                  return "5";
                }
                if (v === "6") {
                  return "6";
                }
                if (v === "7") {
                  return "7";
                }
                if (v === "8") {
                  return "8";
                }
                if (v === "9") {
                  return "9";
                }

                if (v === "10") {
                  return "10";
                }
                if (v === "GreaterThan10") {
                  return "Greater Than 10";
                }
                return true;
              },
            },

            filterOptions: {
              names: [
                "0",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "GreaterThan10",
              ],
              logic: (value, filters) => {
                const numericValue = value;
                if (filters.includes("0") && numericValue != 0) {
                  return true;
                }
                if (filters.includes("1") && numericValue != 1) {
                  return true;
                }
                if (filters.includes("2") && numericValue != 2) {
                  return true;
                }
                if (filters.includes("3") && numericValue != 3) {
                  return true;
                }
                if (filters.includes("4") && numericValue != 4) {
                  return true;
                }
                if (filters.includes("5") && numericValue != 5) {
                  return true;
                }
                if (filters.includes("6") && numericValue != 6) {
                  return true;
                }
                if (filters.includes("7") && numericValue != 7) {
                  return true;
                }
                if (filters.includes("8") && numericValue != 8) {
                  return true;
                }
                if (filters.includes("9") && numericValue != 9) {
                  return true;
                }

                if (filters.includes("10") && numericValue != 10) {
                  return true;
                }
                if (filters.includes("GreaterThan10") && numericValue < 10) {
                  return true;
                }
                return false;
              },
            },
          },
        },

        // {
        //   name: "Subscribed",
        //   label: "Subscribed",
        //   options: {
        //     filter: true,
        //     sort: false,
        //     customBodyRender: (value) => {
        //       return (
        //         // <Chip label={value == false ? "Not Subscribed": "Subscribed" } style={{backgroundColor:'#8FD1AD'}} />
        //         value == "Not Subscribed" ? (
        //           <Chip
        //             label="Not Subscribed"
        //             style={{ backgroundColor: "red" }}
        //           />
        //         ) : (
        //           <Chip
        //             label="Subscribed"
        //             style={{ backgroundColor: "#8FD1AD" }}
        //           />
        //         )
        //       );
        //     },
        //   },
        // },
        {
          name: "phone",
          label: "Phone",
          options: {
            filter: true,
            sort: false,
            display: "false", // Hide column by default
            viewColumns: true, // Allow toggling via column visibility
          },
        },
        {
          name: "qrCode",
          label: "QR Code",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "service_picode",
          label: "Service Pincode",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "pincode",
          label: "Address Pincode",
          options: {
            filter: true,
            sort: false,
          },
        },
        // {
        //   name: "additional_pincode",
        //   label: "Secondary Pincode",
        //   options: {
        //     filter: true,
        //     sort: false,
        //   },
        // },
        {
          name: "primary_services",
          label: "Primary Skill",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "secondary_services",
          label: "Secondary Skill",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "status",
          label: "Status",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "createdAt",
          label: "Created At",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "onboarded_by",
          label: "Onboarded By",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "authorizedFor",
          label: "Authorized For Clients",
          options: {
            filter: true,
            sort: false,
          },
        },
        //
        {
          name: "is_otp_verfied",
          label: "OTP Verification",
          options: {
            filter: true,
            sort: false,
            customBodyRender: (value) => {
              return (
                // <Chip label={value == false ? "Not Subscribed": "Subscribed" } style={{backgroundColor:'#8FD1AD'}} />
                value == true ? (
                  <Chip
                    label="Verified"
                    style={{ backgroundColor: "#8FD1AD" }}
                  />
                ) : (
                  <Chip
                    label="Not Verified"
                    style={{ backgroundColor: "red", color: "white" }}
                  />
                )
              );
            },
          },
        },
        {
          name: "subscription_end_date",
          label: "Subscription end date",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "qrStatus",
          label: "Qr Status",
          options: {
            filter: true,
            sort: false,
          },
        },
        //
        // {
        //   name: "center_obj_id",
        //   label: "Qr Status",
        //   options: {
        //     filter: true,
        //     sort: false,
        //     display: "none",
        //   },
        // },

        {
          name: "Average_Rating",
          label: "Average_Rating",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {value} <StarIcon style={{ color: "yellow" }} />
                </div>
              );
            },
            filter: true,
            sort: false,
          },
        },
        {
          name: "verificationDetails",
          label: "View Verification Details",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              const centerData =
                this.state.centersList[tableMeta.rowIndex]?.action;
              const hasImage =
                centerData &&
                centerData.document_details &&
                centerData.document_details.aadhar_card_document &&
                centerData.document_details.aadhar_card_document.front_side &&
                centerData.document_details.aadhar_card_document.front_side.endsWith(
                  ".jpg"
                );

              return hasImage ? (
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#303F9F" }}
                  onClick={() =>
                    this.toggleVerificationDetailsPopup(centerData)
                  }
                >
                  <RemoveRedEyeIcon />
                  View
                </Button>
              ) : (
                <span>No documents</span>
              );
            },
            filter: true,
            sort: true,
          },
        },
        {
          name: "action",
          label: "Action",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <>
                  {
                    <div
                      style={{
                        display: "flex",
                        width: "550px",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Button
                        className="text-transform-none"
                        variant="contained"
                        onClick={() => this.toggleAuthorizedForPopup(value._id)}
                      >
                        Authorized For
                      </Button>

                      <Button
                        className="text-transform-none"
                        variant="contained"
                        onClick={() => this.redirectToEditCenter(value._id)}
                      >
                        Edit
                      </Button>
                      <Button
                        style={{ background: "#FCE9F1" }}
                        variant="contained"
                        // onClick={() => this.deleteCenter(value._id)}
                        onClick={() => this.clickDeleteCenter(value._id)}
                      >
                        <DeleteIcon style={{ color: "red" }} />
                      </Button>
                      <Button
                        className="text-transform-none"
                        variant="contained"
                        onClick={() => this.centerHistory(value._id)}
                      >
                        History
                      </Button>
                      {moment().diff(moment(value?.extended_date), "days") >
                        15 && (
                        <Button
                          className="text-transform-none"
                          variant="contained"
                          onClick={() => this.extendTrial(value._id)}
                        >
                          Extend Trial
                        </Button>
                      )}
                    </div>
                  }
                </>
              );
            },
          },
        },
      ],
      isAuthorizedForPopupOpen: false,
      isVerificationPopupOpen: false,
      isDeleteAlertPopupOpen: false, // delete alert
      selectedCenterId: "",
      centerDeleteId: "", // center id to be delete
      deleteHeading: "Center",
      selectedCenterData: {},
      isLoading: false,
      adminId: "",
      ticketData: [],

      isSwitchOn: false,
      isSwitchPan: null,
      isSwitchOnAdharFront: null,
      isSwitchOnAdharBack: null,
      panStatus: null,
      profilePicture: null,
      panVerified: false,
      aadhar_font: false,
      aadhar_back: false,
      toast: {
        open: false,
        msg: "",
        duration: 5000,
        type: "",
      },
    };
    this.ticketTableRef = React.createRef();
    this.extendTrial = this.extendTrial.bind(this);
  }

  extendTrial = async (centerId) => {
    let accessToken = localStorage.getItem("access_token");
    const payload = {
      center_obj_id: centerId,
      extended_date: moment().format(),
    };
    try {
      const response = await axios.post(
        `${BASE_URL}admin/update-center-extended-date`,
        payload,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      );
      console.log("Trial extended successfully", response.data);
      this.setState({
        success: "Trial extended successfully",
        error: "",
        snackbar: {
          open: true,
          message: "Trial extended successfully",
          severity: "success",
        },
      });
    } catch (error) {
      console.error("Error extending trial", error);
      this.setState({
        error: "Error extending trial",
        success: "",
        snackbar: {
          open: true,
          message: "Error extending trial",
          severity: "error",
        },
      });
    }
  };
  centerHistory = (centerId) => {
    this.props.history.push(`/center-history/${centerId}`);
  };

  redirectToEditCenter(id) {
    this.props.history.push("/add-center?edit=" + id);
  }

  clickDeleteCenter = (centerId) => {
    this.setState({ centerDeleteId: centerId });
    this.handleOpenCloseDeleteModal(centerId);
    localStorage.setItem("centerId", centerId);
  };

  handleDeleteCloseModal = () => {
    this.setState({
      isDeleteAlertPopupOpen: false,
    });
  };

  deleteCenter = async () => {
    let accessToken = localStorage.getItem("access_token");
    let centerId = localStorage.getItem("centerId");

    try {
      await axios.delete(`${BASE_URL}admin/deleteCenter/${centerId}`, {
        headers: {
          "x-access-token": accessToken,
        },
      });
      this.handleDeleteCloseModal();
      this.getAllCenters();
    } catch (error) {
      console.log(error);
    }
  };

  //center modal open code
  handleOpenCloseDeleteModal = () => {
    this.setState({
      isDeleteAlertPopupOpen: true,
    });
  };

  componentDidMount = () => {
    this.setState({
      adminId: this.props?.reduxState?.adminDetails?._id,
    });
    this.getAllCenters();
    this.getAllClients();
  };

  onClickFilter = (value) => {
    let payload = {
      start_date: value[0],
      end_date: value[1],
    };

    new AdminApiModule()
      .getAllCenters(payload)
      .then((res) => {
        let parsedData = [];
        if (res && res.data && res.data.length > 0) {
          parsedData = this.getParsedData(res.data);
        }
        this.setState({
          centersList: parsedData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  refreshData = () => {
    new AdminApiModule()
      .getAllCenters()
      .then((res) => {
        let parsedData = [];
        if (res && res.data && res.data.length > 0) {
          parsedData = this.getParsedData(res.data);
        }
        this.setState({
          centersList: parsedData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  showDialog = (open, type, msg) => {
    this.setState({
      toast: {
        open: open,
        msg: msg,
        duration: 5000,
        type: type,
      },
    });
  };

  closeDialog = (open = false) => {
    this.setState({
      toast: {
        open: open,
        msg: "",
        duration: 5000,
        type: "",
      },
    });
  };

  startLoading = () => {
    this.setState({ isLoading: true });
  };

  stopLoading = () => {
    this.setState({ isLoading: false });
  };

  getAllClients() {
    new AdminApiModule()
      .getAllClients()
      .then((res) => {
        let parsedData = [];
        if (res && res.data && res.data.length > 0) {
          parsedData = res.data;
        }

        this.setState({
          clientsList: parsedData,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getFormattedServices = (services) => {
    let secondary_services_list = [];
    services &&
      services.length > 0 &&
      services.forEach((item) => {
        // Split the service name by '-'
        const serviceName = item?.secondary_services_id?.service_name;
        if (serviceName) {
          const parts = serviceName.split("-");
          // Take the first part before '-'
          const serviceNameFirstPart = parts[0]?.trim();
          if (serviceNameFirstPart) {
            secondary_services_list.push(serviceNameFirstPart);
          }
        }
      });
    return secondary_services_list.join(", ");
  };

  getFormattedClients = (clients) => {
    let clients_list = [];
    clients &&
      clients.length > 0 &&
      clients.map((item) => {
        clients_list.push(item.client_name);
      });
    return clients_list.length > 0 ? clients_list.join(", ") : "";
  };

  getParsedData = (data) => {
    let dateFormat = { year: "numeric", month: "short", day: "numeric" };
    let parsedData =
      data &&
      data.length > 0 &&
      data.map((item) => {
        return {
          // center_id: item._id,

          center_name: item?.center_name,
          Subscribed:
            item?.payment_details?.paid_for_onboarding_kit === false
              ? "Not Subscribed"
              : "Subscribed",
          phone: item?.personal_details?.phone?.mobile_number,
          qrCode: item?.qr_details?.qr_id,
          qrStatus: item?.qr_details?.qrStatus == true ? "True" : "False",
          subscription_end_date: item?.payment_details?.subscribe_end_date
            ? item.payment_details?.subscribe_end_date
            : "Not subscribed",

          service_picode: item?.services?.pincodes.join(", "),
          pincode: item?.address_details?.pincode,
          additional_pincode: item?.address_details?.additional_pincode,
          primary_services: `${
            item?.services?.primary_services &&
            item?.services?.primary_services.length > 0
              ? item?.services?.primary_services[0]?.service_name
                  .split("-")[0]
                  ?.trim()
              : ""
          }`,

          secondary_services: `${this?.getFormattedServices(
            item?.services?.secondary_services
          )}`,
          status: activeStatus[item.is_active],
          createdAt: new Date(item?.createdAt)?.toLocaleDateString(
            "en-US",
            dateFormat
          ),
          // onboarded_by: item?.onboarded_by?.name,
          onboarded_by: item?.referral_received ? item?.referral_received : "",
          authorizedFor: this.getFormattedClients(item?.clients_ids_list),
          verify: {
            checked: item?.verification_status === "true",
            value: item?.verification_status || "", // add a default value of ""
          },
          action: item,
          closedTickets:
            parseFloat(item?.count_details?.closed_ticket_count) || 0,
          Average_Rating: item?.count_details?.average_ticket_rating,
          verify:
            item?.document_details?.aadhar_card_document?.aadhar_back_verified,
          center_obj_id: item?._id,
          is_otp_verfied: item?.otp_details?.is_otp_verfied,
        };
      });

    return parsedData;
  };

  getAllCenters() {
    this.startLoading();

    new AdminApiModule()
      .getAllCenters()
      .then((res) => {
        const parsedData = this.getParsedData(res?.data);
        this.setState({ centersList: parsedData, isLoading: false });
      })
      .catch((err) => {
        console.log(err);
        this.stopLoading();
      });
  }

  // customBodyRender1 = (value, tableMeta) => {
  //   const centerData =
  //     this.state.centersList[tableMeta.rowIndex]?.action;

  //     const centerId =
  //     this.state.centersList[tableMeta.rowIndex]?.action;
  //   return (
  //     <>
  //       {

  //         <>

  //           <Button
  //             sx={{backgroundColor: centerData == undefined ? "red": "green"}}
  //             className="text-transform-none"
  //             variant="contained"
  //             onClick={() => this.toggleVerificationDetailsPopup(centerData,centerId)}

  //           >
  //              { centerData == undefined ? "" : <RemoveRedEyeIcon
  //               style={{ fontSize: "18px", marginRight: "10px" }}
  //             />}

  //            { centerData == undefined ? "No Image " : "View"}
  //           </Button>
  //         </>
  //       }
  //     </>
  //   );
  // };

  toggleAuthorizedForPopup = (centerId) => {
    if (centerId) {
      this.setState({ selectedCenterId: centerId });
    }
    this.setState((prevState) => ({
      isAuthorizedForPopupOpen: !prevState.isAuthorizedForPopupOpen,
    }));
  };

  //   getRecentDocumentVerify =(centerId)=>{
  //     const access_token = localStorage.getItem("access_token");
  //     // const center_id = localStorage.getItem('centerID');
  //     axios
  //       .get(`${BASE_URL}admin/getCenterDetails/${centerId}`, {
  //         headers: {
  //           Authorization: access_token,
  //         },
  //       })
  //       .then((res) => {
  //         // setIsSwitchOn(res?.data?.data?.document_details?.pan_verified);
  //         // this.setState({isSwitchPan: res?.data?.data?.CenterDetails?.cenData?.document_details?.pan_verified})
  //         // this.setState({isSwitchOnAdharFront: res?.data?.data?.CenterDetails?.cenData?.document_details?.aadhar_card_document?.aadhar_front_verified })
  //         // this.setState({isSwitchOnAdharBack: res?.data?.data?.CenterDetails?.cenData?.document_details?.aadhar_card_document?.aadhar_back_verified })
  //         const selectedCenterData = res?.data?.data?.CenterDetails?.cenData;
  //             this.setState({ selectedCenterData });
  // console.log("+++++++++++",selectedCenterData)
  //             // Call toggleVerificationDetailsPopup and pass selectedCenterData
  //             this.toggleVerificationDetailsPopup(selectedCenterData);
  //         // this.setState({panVerified: res?.data?.data?.document_details?.pan_verified })
  //       })
  //       .catch((error) => {
  //         console.error("API error:", error);
  //       });

  //   }

  toggleVerificationDetailsPopup = (centerData) => {
    console.log("======ssssssss", centerData);
    const access_token = localStorage.getItem("access_token");
    // const center_id = localStorage.getItem('centerID');
    axios
      .get(`${BASE_URL}admin/getCenterDetails/${centerData?._id}`, {
        headers: {
          Authorization: access_token,
        },
      })
      .then((res) => {
        const selectedCenterData = res?.data?.data?.CenterDetails?.cenData;
        this.setState({ selectedCenterData });
        console.log("+++++++++++", selectedCenterData);
      })
      .catch((error) => {
        console.error("API error:", error);
      });
    localStorage.setItem("centerID", centerData?._id);
    console.log("=======", centerData);
    const centerId = centerData?._id;
    //this.setState({ selectedCenterData: centerData });
    this.setState({ selectedCenterId: centerId });
    console.log("99999999999999", centerId);
    if (centerData) {
      // this.getRecentDocumentVerify(centerId)
    }
    this.setState((prevState) => ({
      isVerificationPopupOpen: !prevState.isVerificationPopupOpen,
    }));
  };

  // Aadhar card front status verification code

  handleAdharFront = () => {
    const checkCurrent = this.state.isSwitchOnAdharFront;

    // Update the state directly instead of using prevState
    this.setState(
      {
        isSwitchOnAdharFront: !checkCurrent,
      },
      () => {
        // Call sendPayloadToAPI after updating the state
        this.adharFrontPayload(this.state.isSwitchOnAdharFront)
          .then(() => {
            console.log("API call successful");
          })
          .catch((error) => {
            console.error("API error:", error);
            // Revert the state back to its previous value if the API call fails
            this.setState((prevState) => ({
              isSwitchOnAdharFront: !prevState.isSwitchOnAdharFront,
            }));
          })
          .finally(() => {
            // Call getRecentDocumentVerify after API call completes, regardless of success or failure
            this.getRecentDocumentVerify();
          });
      }
    );
    setTimeout(() => {
      // this.toggleVerificationDetailsPopup();
      this.getRecentDocumentVerify();
    }, 3000);
  };

  adharFrontPayload = (status, value) => {
    return new Promise((resolve, reject) => {
      const centerId = this.selectedCenterData;
      const checkStatus = status.toString();
      const payload = {
        Admin_id: this.state.adminId,
        status: checkStatus,
        center_id: this.state.selectedCenterId,
        value: "aadhar_front",
      };

      axios
        .post(
          `${BASE_URL}admin/Set-Verification-Status-Of-center-And-Technician`,
          payload
        )
        .then((response) => {
          resolve(response.data); // Resolve the promise with the response data
          this.getRecentDocumentVerify();
          this.setState({
            toast: {
              open: true,
              msg: "Aadhar Card Front Document Verified Done.",
              duration: 5000,
              type: "success",
            },
          });
        })
        .catch((error) => {
          console.error("API error:", error);
          reject(error); // Reject the promise with the error
        });
    });
  };

  // Aadhar card front status verification code

  handleAdharBack = () => {
    const checkCurrent = this.state.isSwitchOnAdharBack;

    // Update the state directly instead of using prevState
    this.setState(
      {
        isSwitchOnAdharBack: !checkCurrent,
      },
      () => {
        // Call sendPayloadToAPI after updating the state
        this.adharBackPayload(this.state.isSwitchOnAdharBack)
          .then(() => {
            console.log("API call successful");
          })
          .catch((error) => {
            console.error("API error:", error);
            // Revert the state back to its previous value if the API call fails
            this.setState((prevState) => ({
              isSwitchOnAdharBack: !prevState.isSwitchOnAdharBack,
            }));
          })
          .finally(() => {
            // Call getRecentDocumentVerify after API call completes, regardless of success or failure
            this.getRecentDocumentVerify();
          });
      }
    );
    setTimeout(() => {
      // this.toggleVerificationDetailsPopup();
      this.getRecentDocumentVerify();
    }, 3000);
  };

  adharBackPayload = (status, value) => {
    return new Promise((resolve, reject) => {
      const centerId = this.selectedCenterData;
      const checkStatus = status.toString();
      const payload = {
        Admin_id: this.state.adminId,
        status: checkStatus,
        center_id: this.state.selectedCenterId,
        value: "aadhar_back",
      };

      axios
        .post(
          `${BASE_URL}admin/Set-Verification-Status-Of-center-And-Technician`,
          payload
        )
        .then((response) => {
          resolve(response.data); // Resolve the promise with the response data
          // this.panStatus(response.data?.data?.document_details?.pan_verified)
          this.getRecentDocumentVerify();
          this.setState({
            toast: {
              open: true,
              msg: "Aadhar Card Back Document Verified Done.",
              duration: 5000,
              type: "success",
            },
          });
        })
        .catch((error) => {
          console.error("API error:", error);
          reject(error); // Reject the promise with the error
        });
    });
  };

  // Pan card status verification code
  handleSwitchToggle = (documentType) => {
    const docType =
      documentType === 1
        ? "pan"
        : documentType === 2
        ? "aadhar_front"
        : documentType === 3
        ? "aadhar_back"
        : "";

    const currentState = this.state.isSwitchOn;
    const checkCurrent = this.state.isSwitchPan;

    // Update the state directly instead of using prevState
    this.setState(
      {
        isSwitchPan: !checkCurrent,
      },
      () => {
        // Call sendPayloadToAPI after updating the state
        this.sendPayloadToAPI(this.state.isSwitchPan, documentType)
          .then(() => {
            console.log("API call successful");
          })
          .catch((error) => {
            console.error("API error:", error);
            // Revert the state back to its previous value if the API call fails
            this.setState((prevState) => ({
              isSwitchOn: !prevState.isSwitchOn,
            }));
          })
          .finally(() => {
            // Call getRecentDocumentVerify after API call completes, regardless of success or failure
            this.getRecentDocumentVerify();
          });
      }
    );
    setTimeout(() => {
      // this.toggleVerificationDetailsPopup();
      this.getRecentDocumentVerify();
    }, 3000);
  };

  sendPayloadToAPI = (status, value) => {
    return new Promise((resolve, reject) => {
      const centerId = this.selectedCenterData;
      const checkStatus = status.toString();
      const payload = {
        Admin_id: this.state.adminId,
        status: checkStatus,
        center_id: this.state.selectedCenterId,
        // value: value === 1 ? "pan" : value === 2 ? "aadhar_front" : value === 3 ? "aadhar_back" : "",
        value: "pan",
      };

      axios
        .post(
          `${BASE_URL}admin/Set-Verification-Status-Of-center-And-Technician`,
          payload
        )
        .then((response) => {
          resolve(response.data); // Resolve the promise with the response data
          // this.panStatus(response.data?.data?.document_details?.pan_verified)
          this.getRecentDocumentVerify();
          this.setState({
            toast: {
              open: true,
              msg: "Pan Verified Done.",
              duration: 5000,
              type: "success",
            },
          });
        })
        .catch((error) => {
          console.error("API error:", error);
          reject(error); // Reject the promise with the error
        });
    });
  };

  saveAuthorizedCenters = (centersSelected) => {
    let payload = {
      center_obj_id: this.state.selectedCenterId,
      clients_ids_list: [...centersSelected],
    };

    new AdminApiModule()
      .adminUpdateCenter(payload)
      .then((res) => {
        this.showDialog(true, "success", "Center updated successfully");

        setTimeout(() => {
          this.toggleAuthorizedForPopup();
          this.getAllCenters();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        this.showDialog(true, "error", err?.response?.data?.message);
      });
  };

  handleVerifyChange = (event, centerId) => {
    const centersList = [...this.state.centersList];
    const centerIndex = centersList.findIndex((c) => c.action._id === centerId);

    if (centerIndex > -1) {
      centersList[centerIndex].verify.checked = event.target.checked;
      centersList[centerIndex].verify.value = event.target.checked
        ? "true"
        : "false";
      this.setState({ centersList });
    }

    const status = event.target.checked ? "true" : "false";
    const payload = {
      Admin_id: this?.state?.adminId,
      status: status,
      center_id: centerId,
    };
    new AdminApiModule()
      .updateCenterVerifyStatus(payload)
      .then((res) => {
        this.setState({
          toast: {
            open: true,
            msg: "Center verification status updated successfully.",
            duration: 5000,
            type: "success",
          },
        });
      })
      .catch((error) => {
        this.setState({
          toast: {
            open: true,
            msg: "Failed to update center verification status.",
            duration: 5000,
            type: "error",
          },
        });
      });
  };

  handleRowClick = (rowData, rowMeta) => {
    const dataIndex = rowMeta.dataIndex;
    const center_obj_id = this.state.centersList[dataIndex].center_obj_id;

    console.log(rowData, "abcdasdf", rowMeta);
    axios
      .get(`${BASE_URL}center/get-all-center-ticket/${center_obj_id}`)
      .then((response) => {
        console.log("API response:", response.data);
        this.setState({ ticketData: response.data.data }, () => {
          if (this.ticketTableRef.current) {
            this.ticketTableRef.current.scrollIntoView({ behavior: "smooth" });
          }
        });
      })
      .catch((error) => {
        console.error("API error:", error);
      });
  };

  renderTicketTable = () => {
    const { ticketData } = this.state;

    if (ticketData.length === 0) {
      return null;
    }

    const columns = [
      { name: "createdAt", label: "Created At" },
      { name: "ticket_id", label: "Request ID" },
      { name: "ticket_status", label: "Request Status" },
      { name: "customer_name", label: "Customer Name" },
      { name: "specific_requirement", label: "Specific Req" },
      { name: "closedAt", label: "Closed At" },
      { name: "ticket_price", label: "Request Price" },
      // Add more columns as needed
    ];

    const data = [];
    ticketData.map((ticket) => {
      data.push({
        createdAt: new Date(ticket.createdAt).toLocaleDateString("en-US"),
        ticket_id: ticket.ticket_id,
        ticket_status: ticket.ticket_status,
        customer_name: ticket?.personal_details?.name,
        specific_requirement: ticket?.specific_requirement,
        closedAt: ticket.closedAt
          ? new Date(ticket.closedAt).toLocaleDateString("en-US")
          : "N/A",
        ticket_price: ticket.closing_ticket_price,
      });
    });
    return (
      <div ref={this.ticketTableRef} style={{ marginBottom: "20px" }}>
        <MUIDataTable
          title={"Request Details"}
          data={data}
          columns={columns}
          options={{
            filterType: "dropdown",
            selectableRows: "none",
          }}
        />
      </div>
    );
  };

  render() {
    const { toast: snackBarValues } = this.state;
    const { columns } = this.state;
    const { error, success } = this.state;
    const options = {
      filterType: "dropdown",
      print: true,
      onRowClick: this.handleRowClick,
    };

    return (
      <div>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        {snackBarValues.open && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackBarValues.open}
            autoHideDuration={snackBarValues.duration}
            onClose={this.closeDialog}
          >
            <Alert
              onClose={this.closeDialog}
              severity={snackBarValues.type}
              sx={{ width: "100%" }}
            >
              {snackBarValues.msg}
            </Alert>
          </Snackbar>
        )}

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.isLoading}
          onClick={this.stopLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {this.props.reduxState?.adminDetails?.role_id?.permissions
          ?.view_center_details?.full_access ? (
          <>
            <BasicDateRangePicker
              onClickFilter={this.onClickFilter}
              refreshData={this.refreshData}
              style={{ marginBottom: "20px" }} // Add margin
            />
            <div style={{ marginBottom: "20px" }}>
              {" "}
              {/* Add margin */}
              <MUIDataTable
                title={"All Centers"}
                data={this.state.centersList}
                columns={this.state.columns}
                options={options}
              />
            </div>
            <div style={{ marginBottom: "20px" }}>
              {" "}
              {/* Add margin */}
              {this.renderTicketTable()}
            </div>
          </>
        ) : (
          "No access , contact admin"
        )}

        {this.state.isDeleteAlertPopupOpen && (
          <DeleteAlertDialogModal
            openAlert={this.state.isDeleteAlertPopupOpen}
            handleAlertClose={this.handleDeleteCloseModal}
            handleDeleteTicket={this.deleteCenter}
            Heading={this.state.deleteHeading}
          />
        )}

        <ViewActions
          isAuthorizedForPopupOpen={this.state.isAuthorizedForPopupOpen}
          clientsList={this.state.clientsList}
          toggleAuthorizedForPopup={this.toggleAuthorizedForPopup}
          saveAuthorizedCenters={this.saveAuthorizedCenters}
        />
        <ViewVerify
          isVerificationPopupOpen={this.state.isVerificationPopupOpen}
          toggleVerificationDetailsPopup={this.toggleVerificationDetailsPopup}
          selectedCenterData={this.state.selectedCenterData}
          adminId={this.state.adminId}
          selectedCenterId={this.state.selectedCenterId}
          panVerified={this.state.panVerified}
          aadhar_font={this.state.aadhar_font}
          aadhar_back={this.state.aadhar_back}
          panSwitch={this.state.isSwitchPan}
          aadharFront={this.state.isSwitchOnAdharFront}
          aadharBack={this.state.isSwitchOnAdharBack}
          handleSwitchToggle={this.handleSwitchToggle}
          handleAdharFront={this.handleAdharFront}
          handleAdharBack={this.handleAdharBack}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  reduxState: state.userReducer,
});

export default connect(mapStateToProps, null)(withRouter(Centers));
