import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { NavBar } from "./Navbar";
import "./serviceStatus.css";
import { createTheme, ThemeProvider } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import { Footer } from "./Footer";
import Rating from "@mui/material/Rating";
import TechnicianInfoModal from "./TecnicianInfoModal";
import playIcon from "./Images/NoPath-Copy(25).png";
import { BASE_URL } from "../../components/config/config";
import Chip from "@mui/material/Chip";
import HistoryIcon from "@mui/icons-material/History";
import { makeStyles } from "@mui/styles";
import RebookModal from "../Components/RebookMoadl";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@material-ui/core/Tooltip";
import RevisitModal from "../Components/RevisitModal";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
const steps = [
  {
    label: "Ticket Created",
  },
  {
    label: "Ticket Assigned To Technician",
  },
  {
    label: "Job Started",
  },
  {
    label: "Job Completed",
  },
  {
    label: "Invoice Generated",
  },
  {
    label: "Feedback Received",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiRating-iconEmpty": {
      color: "white", // Customize the color for inactive stars
    },
  },
}));

const theme = createTheme({ typography: { fontSize: 18 } });

export default function VerticalLinearStepper() {
  debugger;
  const [activeStep, setActiveStep] = React.useState(0);
  // const { id } = useParams();
  const location = useLocation();
  const id = location.search.slice(1);
  const [status, setStatus] = React.useState([]);
  const [status1, setStatus1] = React.useState([]);

  // Rebook code
  const [openRebook, setRebookOpen] = React.useState(false);
  const [openRevisit, setRevisitOpen] = React.useState(false);
  const [ticketId, setTicketId] = useState("");

  const handleRebookOpen = (TicketId) => {
    setRebookOpen(true);
    setTicketId(TicketId);
  };
  console.log(ticketId, "<------This is the data😊😊😊😊😊😊😊😊😊😊😊😊");
  const handleRevisitOpen = () => setRevisitOpen(true);
  const handleRebookClose = () => setRebookOpen(false);
  const handleRevisitClose = () => setRevisitOpen(false);

  const classes = useStyles();
  React.useEffect(() => {
    const data = {
      _id: id,
    };

    const headers = {
      "Content-Type": "application/json",
    };

    const res = axios
      .post(`${BASE_URL}technicianApp/getStatus`, data, {
        headers,
      })
      .then((response) => {
        console.log(response.data);
        sessionStorage.setItem("ticketStatusId", id);
        setStatus(response?.data?.data);
      })
      .catch((error) => {
        console.error(error);
      });

    // get
    const res1 = axios
      .get(`${BASE_URL}admin/get-all-ticket?ticket_obj_id=${id}`, {
        headers,
      })
      .then((response) => {
        console.log(response.data);
        // sessionStorage.setItem("data", response?.data?.data);
        setStatus1(response?.data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  const isTechAssign = status1[0]?.is_technician_assigned;
  const techDetails = status1[0];

  console.log("status", status);
  console.log("techDetails", techDetails);

  const startRating = status1?.[0]?.remarks?.star_rating;

  const [feedbackValue, setFeedbackValue] = React.useState(
    parseInt(startRating)
  );

  const sendFeedback = (rating) => {
    const headers = {
      "Content-Type": "application/json",
    };
    const data = {
      rating: rating,
    };
    // Feedback update
    const update = axios
      .put(`${BASE_URL}technicianApp/setStatusRating/${id}`, data, { headers })
      .then((response) => {
        console.log("Feedback updated:", response.data);
        // setFeedbackValue(response.data.rating);
        setFeedbackValue((prevValue) => response.data.rating);
        window.location.reload();
        // forceUpdate();
      })
      .catch((error) => {
        console.error(error);
      });
    //  setFeedbackValue(rating)
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  console.log("startRating", startRating);
  console.log("feedback", startRating);
  return (
    <>
      <NavBar />
      <div id="main_container">
        <div className="status_container">
          <div className="status_box">
            <ThemeProvider theme={theme}>
              {status?.includes("NOT_ASSIGNED") ? (
                <Stepper activeStep={1} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step
                      key={step.label}
                      sx={{
                        "& .MuiStepLabel-root .Mui-completed": {
                          color: "green", // circle color (COMPLETED)
                        },
                        "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                          {
                            color: "grey.500", // Just text label (COMPLETED)
                          },
                        "& .MuiStepLabel-root .Mui-active": {
                          color: "secondary.main", // circle color (ACTIVE)
                        },
                        "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                          {
                            color: "common.white", // Just text label (ACTIVE)
                          },
                        "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                          fill: "white", // circle's number (ACTIVE)
                        },
                      }}
                    >
                      <StepLabel>
                        <span
                          style={{
                            marginLeft: "5px",
                            fontSize: "85%",
                            whiteSpace: "nowrap",
                            wordSpacing: "2px",
                            letterSpacing: "3px",
                            color: "white",
                          }}
                        >
                          {step.label}
                        </span>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              ) : (
                <>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Stepper
                      activeStep={
                        status.length >= 1 ? status.length + 1 : status.length
                      }
                      orientation="vertical"
                    >
                      {steps.map((step, index) => (
                        <Step
                          key={step.label}
                          sx={{
                            "& .MuiStepLabel-root .Mui-completed": {
                              color: "green", // circle color (COMPLETED)
                            },
                            "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                              {
                                color: "grey.500", // Just text label (COMPLETED)
                              },
                            "& .MuiStepLabel-root .Mui-active": {
                              color: "secondary.main", // circle color (ACTIVE)
                            },
                            "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                              {
                                color: "common.white", // Just text label (ACTIVE)
                              },
                            "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text":
                              {
                                fill: "white", // circle's number (ACTIVE)
                              },
                          }}
                        >
                          <StepLabel>
                            <span
                              style={{
                                marginLeft: "5px",
                                fontSize: "85%",
                                whiteSpace: "nowrap",
                                wordSpacing: "2px",
                                letterSpacing: "3px",
                                color: "white",
                              }}
                            >
                              {step.label}
                            </span>
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                    {/* {startRating >= 0 && (
                <span
                  className="glow_border"
               
                >

                  <Typography sx={{marginLeft:'10px'}} variant="caption">
                  Rate Us :-
                  </Typography>

                  <Rating
                    className={classes.root}
                  
                    size="small"
                    defaultValue={startRating}
                    name="simple-controlled"
                    value={feedbackValue || startRating}
                    onChange={(event, newValue) => {
                      sendFeedback(newValue);
                    }}
                  />
                </span>
              )} */}
                  </Box>
                </>
              )}
            </ThemeProvider>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box className="status_details">
              {status1?.map((ele, indx) => {
                return (
                  <>
                    <div
                      className="status_details_data"
                      style={{ color: "whitesmoke" }}
                    >
                      <p>
                        Request ID : <span>{ele?.ticket_id}</span>{" "}
                      </p>

                      <p>
                        Service Requested :{" "}
                        <span style={{ fontSize: "13px" }}>
                          {ele?.service_provided_for?.service_name}
                          ...
                        </span>{" "}
                      </p>

                      <p>
                        For :{" "}
                        <span style={{ fontSize: "13px" }}>
                          {ele?.specific_requirement}
                        </span>{" "}
                      </p>

                      <p>
                        User Name : <span>{ele?.personal_details?.name}</span>{" "}
                      </p>

                      {/* condition render */}
                      {isTechAssign && (
                        <p>
                          Technician Assigned:{" "}
                          <span>
                            {ele?.is_technician_assigned == true ? "Yes" : "No"}
                            {/* {ele?.is_technician_assigned == true && ( */}
                            {/* <Tooltip title="Technician Details"> */}
                            {/* <Button onClick={handleOpen}>
                                  <InfoIcon style={{ color: "orange" }} />
                                </Button> */}
                            {/* </Tooltip> */}
                            {/* )} */}

                            {/* Technician Modal */}
                            <TechnicianInfoModal
                              openTechModal={open}
                              techDetails={techDetails}
                              handleOpen={handleOpen}
                              handleClose={handleClose}
                            />
                          </span>{" "}
                        </p>
                      )}

                      <p
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        Technician Name :{" "}
                        <span style={{ margin: "0 9px" }}>
                          {
                            ele?.assigned_ids?.assigned_technician_id
                              ?.personal_details?.name
                          }
                        </span>
                        <a
                          style={{ textDecoration: "none", color: "#00796A" }}
                          href={`tel:+${ele?.assigned_ids?.assigned_center_id?.center_name}`}
                        >
                          {" "}
                        </a>{" "}
                      </p>
                      {/* <p>
                        Job Completed :{" "}
                        <span style={{ fontSize: "13px" }}>
                          {techDetails?.ticket_status == "CLOSED" ? (
                            <Chip
                              label="DONE"
                              style={{
                                color: "#00FFCA",
                                borderColor: "#00FFCA",
                                fontWeight: "700",
                              }}
                              variant="outlined"
                            />
                          ) : (
                            "---"
                          )}
                        </span>
                      </p> */}
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        Contact NO. :{" "}
                        <a
                          style={{ textDecoration: "none", color: "#00796A" }}
                          href={`tel:+${ele?.assigned_ids?.assigned_technician_id?.personal_details?.phone?.mobile_number}`}
                        >
                          <PhoneIcon />{" "}
                        </a>{" "}
                        <span style={{ margin: "0 9px" }}>
                          {
                            ele?.assigned_ids?.assigned_center_id
                              ?.personal_details?.phone?.mobile_number
                          }
                        </span>
                      </p>

                      <p>
                        Booking Amount : <span>{ele?.ticket_price || 0}</span>{" "}
                      </p>
                      {/* <p>
                        Wallet Balance :{" "}
                        <span>{ele?.ticket_price || 0}</span>{" "}
                      </p> */}
                      <p>
                        Invoiced Amount :{" "}
                        <span>{ele?.closing_ticket_price || "--"}</span>{" "}
                      </p>

                      <p>
                        {/* condition render styling*/}
                        Status :{" "}
                        <Chip
                          label={ele?.ticket_status}
                          style={{
                            color:
                              ele?.ticket_status == "PENDING"
                                ? "yellow"
                                : ele?.ticket_status == "CLOSED"
                                ? "red"
                                : ele?.ticket_status == "ACCEPTED"
                                ? "green"
                                : "none",
                            fontWeight: "1000",

                            borderColor:
                              ele?.ticket_status == "PENDING"
                                ? "yellow"
                                : ele?.ticket_status == "CLOSED"
                                ? "red"
                                : ele?.ticket_status == "ACCEPTED"
                                ? "green"
                                : "none",
                          }}
                          variant="outlined"
                        />
                      </p>

                      {status1[0]?.onsite_pictures?.length > 0 && (
                        <Box mt={3}>
                          <div>On-Site Images:</div>
                          <Box display="flex" flexWrap="wrap" gap={2} mt={1}>
                            {status1[0].onsite_pictures.map((image, index) => (
                              <Box
                                key={index}
                                component="img"
                                src={image}
                                alt={`On-site image ${index + 1}`}
                                width={100}
                                height={100}
                                borderRadius={3}
                              />
                            ))}
                          </Box>
                        </Box>
                      )}
                      <p>
                        {ele?.ticket_status == "CLOSED" && (
                          <Box
                            display="flex"
                            justifyContent="space-around"
                            mt={3}
                          >
                            <button
                              class="invoice_button"
                              // onClick={()=>handleRebookOpen(ele?._id)}
                            >
                              <DownloadIcon />
                              Download Invoice
                            </button>
                            <button
                              class="rebook_button"
                              onClick={() => handleRebookOpen(ele?._id)}
                            >
                              <HistoryIcon />
                              Rebook
                            </button>
                            {ele.ticket_status === "CLOSED" &&
                              moment(ele.closedAt).isAfter(
                                moment().subtract(29, "days")
                              ) && (
                                <button
                                  class="revisit_button"
                                  onClick={handleRevisitOpen}
                                >
                                  <FlipCameraAndroidIcon />
                                  Revisit
                                </button>
                              )}
                          </Box>
                        )}
                      </p>
                    </div>
                    {console.log("ele", ele)}
                    {/* Rebook Modal */}
                    <RebookModal
                      isOpen={openRebook}
                      handleRebookOpen={handleRebookOpen}
                      handleRebookClose={handleRebookClose}
                      Ticket_id={ele?._id}
                    />

                    <RevisitModal
                      isOpen={openRevisit}
                      handleRevisitOpen={handleRevisitOpen}
                      handleRevisitClose={handleRevisitClose}
                      Ticket_id={ele?._id}
                    />
                  </>
                );
              })}
            </Box>
            <div className="center-container">
              {startRating >= 0 && (
                <span
                  className="rating-container"
                  style={{ marginTop: "20px" }}
                >
                  <span className="glow_border">
                    <Typography
                      sx={{ marginLeft: "10px", fontSize: "1.5rem" }}
                      variant="caption"
                    >
                      Rate Us:
                    </Typography>
                    <Rating
                      className={classes.root}
                      size="large" // Change size to 'large'
                      defaultValue={startRating}
                      name="simple-controlled"
                      value={feedbackValue || startRating}
                      onChange={(event, newValue) => {
                        sendFeedback(newValue);
                      }}
                    />
                  </span>
                </span>
              )}
            </div>

            {/* Play button */}
            <a
              className="PlayBtn"
              href="https://play.google.com/store/apps/details?id=com.onit.consumer"
            >
              <img src={playIcon} />
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
