import React, { Component } from "react";
import MUIDataTable from "mui-datatables";

import "../Styles/style.css";
import DeleteIcon from "@mui/icons-material/Delete";

import TechnicianApi from "../components/technicianComponent/api/api";
import Button from "@mui/material/Button";
import { withRouter } from "react-router";
import { Tooltip } from "@mui/material";
import { Box } from "@mui/material";
import axios from "axios";
import { BASE_URL } from "../components/config/config";
import { connect } from "react-redux";
import DeleteAlertDialogModal from "../components/Components/DeleteAlert";
import { Alert, InputAdornment, Snackbar, Typography } from "@mui/material";

const options = {
  filterType: "dropdown",
  download: false,
  print: false,
};

class Technicians extends Component {
  constructor(props) {
    super(props);
    this.state = {
      technicianList: [],
      isDeleteAlertPopupOpen: false, // delete alert
      deleteTechnicianId: "",
      deleteHeading: "Technician",
      columns: [
        {
          name: "name",
          label: "Name",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "email",
          label: "email",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "phone",
          label: "phone",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "profile_created_by",
          label: "profile created by",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "referenceDetails",
          label: "reference Details",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "address_details_permanent",
          label: "address details permanent",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "associatedWith",
          label: "Associated CenterCode",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
          name: "action",
          label: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              console.log({
                value,
                tableMeta,
                updateValue,
              });
              return (
                <Box sx={{ width: "240px" }}>
                  <Button
                    onClick={() => this.handleEditTechnician(value?._id)}
                    variant="contained"
                    color="primary"
                  >
                    Edit Technician
                  </Button>
                  <Tooltip title="Delete">
                    <Button
                      ml={2}
                      style={{ background: "#FCE9F1", marginLeft: "10px" }}
                      onClick={() => this.clickDeleteTicket(value?._id)}
                    >
                      <DeleteIcon style={{ color: "red" }} />
                    </Button>
                  </Tooltip>
                </Box>
              );
            },
          },
        },
      ],
      toast: {
        open: false,
        msg: "",
        duration: 5000,
        type: "",
      },
    };
  }

  componentDidMount() {
    this.getAllTechnicians();
  }

  getAllTechnicians() {
    new TechnicianApi()
      .getAllTechnician()
      .then((res) => {
        let mappedTechnician = res?.data?.map((ite) => {
          return {
            name: ite?.personal_details?.name,
            email: ite?.personal_details?.email,
            phone:
              ite?.personal_details?.phone?.country_code +
              ite?.personal_details?.phone?.mobile_number,
            profile_created_by: ite?.profile_created_by,
            referenceDetails: ite?.referenceDetails?.reference_person_name,
            address_details_permanent:
              ite?.address_details_permanent?.address_line +
              "  " +
              ite?.address_details_permanent?.city +
              " " +
              ite?.address_details_permanent?.country,
            associatedWith: this.getAssociatedCenters(ite?.center_id),
            action: ite,
          };
        });

        this.setState({ technicianList: mappedTechnician });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  showDialog = (open, type, msg) => {
    this.setState({
      toast: {
        open: open,
        msg: msg,
        duration: 5000,
        type: type,
      },
    });
  };

  closeDialog = (open = false) => {
    this.setState({
      toast: {
        open: open,
        msg: "",
        duration: 5000,
        type: "",
      },
    });
  };

  getAssociatedCenters = (centersList) => {
    let centers_list = [];
    centersList &&
      centersList.length > 0 &&
      centersList.map((item) => {
        centers_list.push(item?.qr_details?.qr_id);
      });
    return centers_list.length > 0 ? centers_list.join(", ") : "";
  };

  handleEditTechnician = (id) => {
    this.props.history.push("/AddTechnician?edit=" + id);
  };

  // Delete

  clickDeleteTicket = (ticketId) => {
    this.setState({ deleteTechnicianId: ticketId });
    this.handleOpenCloseTicketDeleteModal(ticketId);
    localStorage.setItem("technicianId", ticketId);
  };

  handleOpenCloseTicketDeleteModal = () => {
    this.setState({
      isDeleteAlertPopupOpen: true,
    });
  };

  handleDeleteCloseModal = () => {
    this.setState({
      isDeleteAlertPopupOpen: false,
    });
  };

  handleDeleteRows = async () => {
    let accessToken = this.props.reduxState?.userTokens?.accessToken;
    console.log(accessToken, "<------This is the data😊😊😊😊😊😊😊😊😊😊😊😊");

    let technician_Id = localStorage.getItem("technicianId");

    try {
      await axios.delete(
        `${BASE_URL}center/deleteTechnician/${technician_Id}`,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      );
      this.handleDeleteCloseModal();
      // this.getAllTickets();
      this.showDialog(true, "success", "Technician Delete successfully");
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { toast: snackBarValues } = this.state;
    return (
      <div>
        {snackBarValues.open && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackBarValues.open}
            autoHideDuration={snackBarValues.duration}
            onClose={this.closeDialog}
          >
            <Alert
              onClose={this.closeDialog}
              severity={snackBarValues.type}
              sx={{ width: "100%" }}
            >
              {snackBarValues.msg}
            </Alert>
          </Snackbar>
        )}

        <MUIDataTable
          title={"Technicians"}
          data={this.state.technicianList}
          columns={this.state.columns}
          options={options}
        />

        {this.state.isDeleteAlertPopupOpen && (
          <DeleteAlertDialogModal
            openAlert={this.state.isDeleteAlertPopupOpen}
            handleAlertClose={this.handleDeleteCloseModal}
            handleDeleteTicket={this.handleDeleteRows}
            Heading={this.state.deleteHeading}
          />
        )}
      </div>
    );
  }
}

// export default withRouter(Technicians);

const mapStateToProps = (state) => ({
  reduxState: state.userReducer,
});
export default connect(mapStateToProps, null)(withRouter(Technicians));
