import React, { useEffect, useState } from "react";
import useRazorpay from "react-razorpay";
import { BASE_URL } from "../components/config/config";
import axios from "axios";
import Modal from "@mui/material/Modal";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CopyButton from "../components/ServicesStatus/ClipbaordButton";

import "../Styles/style.css";

import VerifiedIcon from "@mui/icons-material/Verified";
import List from "@mui/material/List";
import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

export default function RazorPayment({
  tickectId,
  phonenumber,
  isPickDrop = false,
  amount,
  consumerId,
}) {
  const [Razorpay] = useRazorpay();

  const [ticketModalData, setTicketModalData] = useState([]);
  const [ticketModalOpen, setTicketModalOpen] = useState(false);
  // const [transactionId, setTransactionId] = useState(null);

  useEffect(() => {
    handleInitiatePayment();
  }, []);

  const handleInitiatePayment = async () => {
    try {
      await axios({
        method: "get",
        url: `${BASE_URL}center/get-razorpay-key`,
      }).then((res) => {
        const options = {
          key: res?.data?.data?.key_id,
          amount: isPickDrop ? amount * 100 : 99 * 100,
          currency: "INR",
          name: "OnIt Services",
          description: "Request Booking",
          image: "https://onit.services/static/media/main_logo.406f2fdc.png",

          handler: (res1) => {
            if (isPickDrop) {
              if (res1?.razorpay_payment_id?.trim()) {
                handleUpdatePickDropStatus(
                  res1?.razorpay_payment_id,
                  "success"
                );
              } else {
                handleUpdatePickDropStatus(res1?.razorpay_payment_id, "failed");
              }
            } else {
              if (res1.razorpay_payment_id?.trim()) {
                handleUpdateTicketStatus(res1?.razorpay_payment_id, "success");
              } else {
                handleUpdateTicketStatus(res1?.razorpay_payment_id, "failed");
              }
            }
          },
          prefill: {
            name: "onit",
            email: "email.com",
            contact: phonenumber,
          },

          theme: {
            color: "#3399cc",
          },
        };

        const rzpay = new Razorpay(options);
        rzpay.open();
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleUpdatePickDropStatus = async (transactionId, status) => {
    const payload = {
      ticket_obj_id: tickectId,
      status: status, //[success, failed]
      amount: amount,
      userId: consumerId,
      transactionId: transactionId,
    };
    try {
      const res = await axios({
        method: "post",
        url: `${BASE_URL}payment/capture-razorpay-payment`,
        data: payload,
      });
      console.log(res, "responsepick");
      if (res?.data?.success === 1) {
        setTicketModalData(res?.data?.data);
        alert("Your request created successfully");
        setTicketModalOpen(true);
      }
    } catch (err) {
      alert("Payment failed! Your request not created");
    }
  };
  const handleUpdateTicketStatus = async (transactionId, status) => {
    const payload = {
      ticket_obj_id: tickectId,
      status: status, //[success, failed]
      amount: 99,
      userId: consumerId,
      transactionId: transactionId,
    };
    try {
      const res = await axios({
        method: "post",
        url: `${BASE_URL}payment/capture-payment-broadcastTicket`,
        data: payload,
      });

      if (res?.data?.success === 1) {
        // this.setState({ ticketModalData: res?.data?.data });
        setTicketModalData(res?.data?.data);
        alert("Your request created successfully");
        setTicketModalOpen(true);
      }
    } catch (err) {
      alert("Payment failed! Your request not created");
    }
  };

  return (
    <div>
      {ticketModalOpen === true ? (
        <Modal
          keepMounted
          open={ticketModalOpen}
          //   open={false}
          onClose={() => {}}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%", // Adjust width for smaller screens
              maxWidth: 700,
              bgcolor: "background.paper",
              border: "2px solid gray",
              boxShadow: 24,
              borderRadius: 4,
              color: "black",
              textAlign: "center", // Center align content
              p: { xs: 2, md: 4 }, // Adjust padding for different screen sizes
              "& .MuiTypography-root": {
                mb: 2, // Adjust margin bottom for typography elements
              },
              "& .MuiList-root": {
                width: "100%", // Ensure list takes full width
              },
              "& .MuiListItem-root": {
                display: "block", // Display list items as blocks
              },
              "& .MuiListItem-root + .MuiDivider-root": {
                margin: "8px 0", // Adjust margin between list items and dividers
              },
              "@media (max-width: 600px)": {
                width: "100%", // Adjust width for smaller screens
                maxWidth: "100%", // Adjust max width for smaller screens
                borderRadius: 0, // Remove border radius for smaller screens if needed
                boxShadow: "none", // Remove box shadow for smaller screens if needed
              },
            }}
          >
            <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
              <VerifiedIcon color="success" fontSize="large" />
              Hurray! Your Request has been created
            </Typography>
            <Typography sx={{ mt: 2 }}>
              {/* List content */}
              <List
                sx={{ width: "100%" }}
                component="nav"
                aria-label="mailbox folders"
              >
                <ListItem button>
                  <ListItemText primary="Ticket Id :" />
                  <Typography>{ticketModalData?.ticket_id}</Typography>
                </ListItem>
                <Divider />
                <ListItem button divider>
                  <ListItemText primary="Ticket Status :" />
                  <Typography>{ticketModalData?.ticket_status}</Typography>
                </ListItem>
                <ListItem button>
                  <ListItemText primary="Created At :" />
                  <Typography>{ticketModalData?.createdAt}</Typography>
                </ListItem>
                <Divider />
                <ListItem button>
                  <ListItemText primary="Tracking Link :" />
                  <Typography>
                    <CopyButton
                      webLink={`https://app.onit.services/#/ServiceStatus/?${ticketModalData?._id}`}
                    />
                    <Link
                      style={{ marginLeft: "17px" }}
                      to={`ServiceStatus/?${ticketModalData?._id}`}
                    >
                      Click me
                    </Link>
                  </Typography>
                </ListItem>
              </List>
            </Typography>
          </Box>
        </Modal>
      ) : null}
    </div>
  );
}
