import React, { useEffect, useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import "./OnsiteFile.css";
import { BASE_URL } from "../config/config";
import axios from "axios";
import AdminApiModule from "../AdminApi/Api/api";
const RevisitFileUploader = ({ onUploadComplete }) => {
  const ticketData = localStorage.getItem("tickeClose_Id");
  const TicketId = localStorage.getItem("tickeClosetId");
  console.log("ticketData", ticketData);
  const [fileNames, setFileNames] = useState([]);
  const [currentField, setCurrentField] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const centerTicketId = localStorage.getItem("centerTicketId");
  const [isImg, setImg] = useState(false);
  const handleFileChange = (e, index) => {
    const newFileNames = [...fileNames];

    if (fileNames.length >= 8) {
      alert("Maximum file limit reached.");
      return;
    }

    if (e.target.files.length > 0) {
      const filename = e.target.files[0].name;
      newFileNames[index] = filename;
      setSelectedImage(e.target.files[0]);
    } else {
      newFileNames[index] = "No file chosen...";
      setSelectedImage(null);
    }

    setFileNames(newFileNames);
    setCurrentField(index + 1); // Move to the next field
  };

  console.log("fileNames", fileNames);
  const handleImagePreview = (event, index) => {
    const file = event.target.files[0];
    const newFileNames = [...fileNames];

    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        newFileNames[index] = reader.result;
        setFileNames(newFileNames);
      };
    }
  };

  const handleImageUpload = async () => {
    if (!selectedImage) {
      console.log("No image selected.");
      return;
    }

    const formData = new FormData();
    formData.append("RevisitPictures", selectedImage);

    try {
      const response = await fetch(
        `${BASE_URL}technicianApp/upload-ticket-revisit-picture/${
          TicketId || centerTicketId
        }`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        // Image uploaded successfully
        console.log("Image uploaded!");
        setImg(true);
        localStorage.setItem("checkImg", true);
        onUploadComplete(); // Call the callback function
      } else {
        // Handle upload error
        console.error("Image upload failed.");
      }
    } catch (error) {
      console.error("Error occurred while uploading the image:", error);
    }
  };

  const renderFileUploadElements = () => {
    const fileUploadElements = [];
    localStorage.setItem("checkImg", isImg);
    for (let i = 0; i <= currentField; i++) {
      fileUploadElements.push(
        <div
          style={{
            margin: "7px",
            display: "flex",
            flexDirection: "column",
            width: "45px",
          }}
          key={i}
        >
          <div className="file-upload">
            <div className="file-select file-select-box">
              {fileNames[i] && fileNames[i].startsWith("data:image/") ? (
                <div
                  className="imagePreview top_layer_preview"
                  style={{ backgroundImage: `url(${fileNames[i]})` }}
                ></div>
              ) : (
                <div className="imagePreview"></div>
              )}

              <button className="file-upload-custom-btn">
                <UploadIcon />
              </button>
              <input
                type="file"
                name="files[]"
                className="profileimg"
                onChange={(event) => {
                  handleImagePreview(event, i);
                  handleFileChange(event, i);
                }}
              />
              <div className="file-select-name">{fileNames[i]}</div>
            </div>
          </div>
          <button
            style={{ fontSize: "11px", color: "black" }}
            disabled={!fileNames[i]}
            onClick={handleImageUpload}
          >
            Upload
          </button>
        </div>
      );
    }

    return fileUploadElements;
  };

  return (
    <div className="container text-center mb-5 mt-5">
      <div className="row">
        <div className="col-md-12">
          <h4>Upload Revisit Image</h4>
        </div>
      </div>

      <section className="bg-diffrent">
        <div className="container">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto auto auto",
              gridGap: "-10px !important",
            }}
          >
            {renderFileUploadElements()}
          </div>
        </div>
      </section>
    </div>
  );
};

export default RevisitFileUploader;
