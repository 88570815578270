import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../components/config/config";
import axios from "axios";
import toast from "react-hot-toast";

function Subscription() {
  const [offers, setOffers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [price_id, setPriceId] = useState(null);
  const [formData, setFormData] = useState({
    annualsubscription: "",
    frist_installment: "",
    request_booking_amount: "",
    subscribed_user_ticket_amount: "",
    unsubscribed_user_ticket_amount: "",
  });

  // Fetching the offers data
  const getAllOffers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}admin/get-all-price-list`);
      const prices = response?.data?.data?.map((item) => item.price);
      const ids = response?.data?.data?.map((item) => item._id);
      setOffers(prices[0]);
      setPriceId(ids[0]);
    } catch (error) {
      console.error("Error fetching offers:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllOffers();
  }, []);

  useEffect(() => {
    if (offers) {
      setFormData({
        annualsubscription: offers.annualsubscription || "",
        frist_installment: offers.frist_installment || "",
        request_booking_amount: offers.request_booking_amount || "",
        subscribed_user_ticket_amount:
          offers.subscribed_user_ticket_amount || "",
        unsubscribed_user_ticket_amount:
          offers.unsubscribed_user_ticket_amount || "",
      });
    }
  }, [offers]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // setFormData((prev) => ({ ...prev, [name]: value }));
    setFormData((prev) => ({
      ...prev,
      [name]: value === "" ? "" : Number(value),
    }));
  };

  const postAllOffers = async (fieldName) => {
    try {
      if (!price_id) {
        console.log("❌ No price ID available");
        return;
      }

      if (!fieldName) {
        console.log("❌ No field specified for update");
        return;
      }

      const fieldValue = Number(formData[fieldName]);

      if (isNaN(fieldValue) || fieldValue === 0) {
        console.log("❌ Invalid or missing value for:", fieldName);
        return;
      }

      let updatedData = {
        price_id,
        price_type: fieldName, // ✅ Ensure price_type is included
        amount: fieldValue, // ✅ API expects 'amount', so we explicitly send it
      };

      console.log("🚀 Sending Data:", updatedData);

      const response = await axios.post(
        `${BASE_URL}admin/updatePriceList`,
        updatedData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      console.log("✅ Offer Updated Successfully:", response.data);

      // ✅ UI में तुरंत अपडेट करें
      setFormData((prev) => ({
        ...prev,
        [fieldName]: fieldValue,
      }));

      // ✅ API से डेटा रीफ्रेश करें
      await toast.success("price updated successfully");
      await getAllOffers();
    } catch (error) {
      console.log(
        "❌ Error on posting:",
        error.response?.data || error.message
      );
    }
  };

  return (
    <div
      style={{
        border: "2px solid white",
        backgroundColor: "#f9f9f9",
        display: "flex",
        flexDirection: "column",
        width: "40vw",
        margin: "auto",
        gap: "1rem",
        color: "black",
        padding: "2rem",
        borderRadius: "12px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
      }}
    >
      <h1 style={{ alignSelf: "center" }}>Subscription</h1>
      {Object.keys(formData).map((key) => (
        <div
          key={key}
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              width: "100%",
            }}
          >
            <label htmlFor={key}>{key.replace(/_/g, " ")}</label>
            <input
              type="text"
              name={key}
              value={formData[key]}
              onChange={handleChange}
              placeholder={key.replace(/_/g, " ")}
              style={{
                padding: "12px",
                border: "1px solid #003161",
                borderRadius: "8px",
                fontSize: "1rem",
                outline: "none",
                transition: "0.3s ease-in-out",
              }}
            />
          </div>
          <button
            style={{
              padding: "12px",
              border: "none",
              borderRadius: "8px",
              fontWeight: "600",
              fontSize: "1rem",
              outline: "none",
              transition: "0.3s ease-in-out",
              backgroundColor: "#68d3bd",
              cursor: "pointer",
            }}
            onClick={() => postAllOffers(key)}
          >
            Update
          </button>
        </div>
      ))}
    </div>
  );
}

export default Subscription;
