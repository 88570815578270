import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../components/config/config";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function CenterHistory() {
  const { centerId } = useParams();
  const [historyData, setHistoryData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchHistoryData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}admin/get-center-feedback/${centerId}`
        );
        setHistoryData(response.data.data);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          console.error("Permission error:", error.response.data.message);
          // Handle permission error, e.g., redirect to a permission denied page
        } else {
          console.error("Error fetching history data:", error);
          // Handle other errors
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchHistoryData();
  }, [centerId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!historyData) {
    return <div>No history data available.</div>;
  }

  return (
    <div>
      <h1>Center History</h1>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Remark Type</TableCell>
              <TableCell>Remarks</TableCell>
              <TableCell>Center Remarks</TableCell>
              <TableCell>Updated By</TableCell>
              <TableCell>Updated On</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {historyData.map((item) => (
              <TableRow key={item._id}>
                <TableCell>
                  {item.remarks_type
                    ? item.remarks_type
                    : item.remarks_type === "" && item.ticket_remarks === ""
                    ? "Enabling"
                    : ""}
                </TableCell>
                <TableCell>{item.remarks}</TableCell>
                <TableCell>{item.ticket_remarks}</TableCell>
                <TableCell>{item.update_name}</TableCell>
                <TableCell>
                  {new Date(item?.updatedAt).toLocaleString("en-IN", {
                    timeZone: "Asia/Kolkata",
                  })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default CenterHistory;
